import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  getPrograms,
  profileGetDataService,
  profileUpdateDataService,
} from "../../services/dataService";
import { toast } from "react-toastify";
import {
  AlertCircle,
  AlertTriangle,
  FolderClosedIcon,
  InfoIcon,
  ShieldCheck,
  X,
} from "lucide-react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import aiml from "../../assets/aicte1.pdf";
import apaar from "../../assets/APAAR ID (1).pdf";
import { Worker, Viewer } from "@react-pdf-viewer/core"; // For displaying the PDF
import "@react-pdf-viewer/core/lib/styles/index.css"; // Default styles
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

// Import the pdfjs-dist worker
import { Document, Page, pdfjs } from "react-pdf";
import { useNavigate } from "react-router-dom";
import { ClosedCaption } from "@mui/icons-material";
import { BASE_URL } from "../../services/configUrls";
import axios from "axios";
import aictesample from "../../assets/aictesample.png";
import aictesample1 from "../../assets/aictesample1.jpeg";
import aictesample2 from "../../assets/aictesample2.jpeg";
// import pdfWorker from "pdfjs-dist/build/pdf.worker.entry"; // PDF.js worker entry

// Set the worker for PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 900,
//   bgcolor: "background.paper",
//   boxShadow: 24,
//   p: 4,
// };

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 600,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflowY: "auto",
};

const populateFormFields = (data, setValue) => {
  // Personal Details
  setValue("first_name", data?.first_name);
  setValue("middle_name", data?.middle_name);
  setValue("last_name", data?.last_name);
  setValue("email", data?.email);
  setValue("mobile", data?.mobile);
  setValue("whatsapp", data?.whatsapp);
  setValue("dob", data?.dob);
  setValue("gender", data?.gender);
  setValue("caste", data?.caste);
  setValue("disability", data?.disability);
  setValue("house_no", data?.house_no);
  setValue("city", data?.city);
  setValue("street_name", data?.street_name);
  setValue("state", data?.state);
  setValue("pincode", data?.pincode);
  setValue("aicteId", data?.aicte_id);
  setValue("apar_id", data?.apar_id);

  // Academic Details - 10th
  setValue("school_name_tenth", data?.school_name_tenth);
  setValue("tenth_mark", data?.tenth_mark);
  setValue("matric_passout_year", data?.matric_passout_year);

  // Academic Details - 12th/Intermediate
  setValue("intermediate_qualification", data?.intermediate_qualification);
  setValue("school_name_twlth", data?.school_name_twlth);
  setValue("twth_mark", data?.twth_mark);
  setValue("intermediate_passout_year", data?.intermediate_passout_year);

  // UG Details
  setValue("university", data?.university);
  setValue("institute_id", data?.institute_id);
  setValue("state_id", data?.state_id);
  setValue("program_id_ug", data?.program_id_ug);
  setValue("branch_ug", data?.branch_ug);
  setValue("semister", data?.semister);
  setValue("roll_no", data?.roll_no);
  setValue("regd_no", data?.regd_no);
  setValue("btech_cgpa", data?.btech_cgpa);
  setValue("year_of_addmission_ug", data?.year_of_addmission_ug);

  // PG Details
  setValue("pg_institute_id", data?.pg_institute_id);
  setValue("pg_college_name", data?.pg_college_name);
  setValue("pg_state_id", data?.pg_state_id);
  setValue("program_id_pg", data?.program_id_pg);
  setValue("branch_pg", data?.branch_pg);
  setValue("semester_pg", data?.semester_pg);
  setValue("roll_no_pg", data?.roll_no_pg);
  setValue("regd_no_pg", data?.regd_no_pg);
  setValue("pg_cgpa", data?.pg_cgpa);
  setValue("year_of_addmission_pg", data?.year_of_addmission_pg);

  // Other Details
  setValue("family_income", data?.family_income);

  // Job Details
  if (data?.is_got_job === "yes") {
    setValue("is_got_job", "yes");
    setValue("company_name", data?.company_name);
    setValue("designation", data?.designation);
    setValue("annual_package", data?.annual_package);
  } else if (data?.is_got_job === "no") {
    setValue("is_got_job", "no");
    setValue("no_job_reason", data?.no_job_reason);
    if (data?.no_job_reason === "other") {
      setValue("no_job_details", data?.no_job_details);
    }
  }
};

const NewPersonalDetailsForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue, // To programmatically set the value of WhatsApp number
  } = useForm();

  useEffect(() => {
    const handleRightClick = (e) => {
      e.preventDefault();
    };

    // Attach the event listener to disable right-click
    document.addEventListener("contextmenu", handleRightClick);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("contextmenu", handleRightClick);
    };
  }, []);

  const [isPermanentSame, setIsPermanentSame] = useState(false);

  const [showDisabilityOptions, setShowDisabilityOptions] = useState(false);
  const [showOtherDisability, setShowOtherDisability] = useState(false); // State to control "Other" input visibility
  const [programs, setPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [branches, setBranches] = useState([]);
  const [showBranchFields, setShowBranchFields] = useState(false);
  const [selectedProgramDuration, setSelectedProgramDuration] = useState(null);
  const [selectedDisabilities, setSelectedDisabilities] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false); // Modal state for AICTE and APAAR info
  const [pdfUrl, setPdfUrl] = useState(""); // PDF URL state
  const [numPages, setNumPages] = useState(null);
  const navigate = useNavigate();
  const [jobStatus, setJobStatus] = useState(null);
  const [reasonForNoJob, setReasonForNoJob] = useState(null);
  const [isAlumni, setIsAlumni] = useState(null);
  const [updateStatus, setUpdateStatus] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showUGBranchFields, setShowUGBranchFields] = useState(false);
  const [showPGBranchFields, setShowPGBranchFields] = useState(false);
  const [showAlumniMessage, setShowAlumniMessage] = useState(false);
  const [ugPassoutYear, setUGPassoutYear] = useState("");
  const [pgPassoutYear, setPGPassoutYear] = useState("");
  const [selectedUGProgramDuration, setSelectedUGProgramDuration] =
    useState(null);
  const [selectedPGProgramDuration, setSelectedPGProgramDuration] =
    useState(null);

  const [ugBranches, setUGBranches] = useState([]);
  const [pgBranches, setPGBranches] = useState([]);
  const [isAicteVerified, setIsAicteVerified] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationError, setVerificationError] = useState("");
  const MAX_FILE_SIZE = 1024 * 1024; // 1MB in bytes
  const [verificationDetails, setVerificationDetails] = useState({
    nstatus: false,
    aictestatus: false,
    // institutestatus: false,
  });
  const [openAicteModal, setOpenAicteModal] = useState(false);
  const [showInitialTooltip, setShowInitialTooltip] = useState(true);
  const [showPdfSection, setShowPdfSection] = useState(false);

  // const [alumniMessage, setAlumniMessage] = useState("");

  // const alumniModalStyle = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   width: "80%",
  //   maxWidth: 600,
  //   bgcolor: "background.paper",
  //   boxShadow: 24,
  //   p: 4,
  // };

  const semesters = ["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th"];

  const admissionYear = watch("year_of_addmission");

  // Watch the value of the radio button for showing/hiding checkboxes
  const disabilityStatus = watch("is_pwd");

  // Watch the value of mobile and WhatsApp number
  const mobileNumber = watch("mobile");
  const isWhatsAppSame = watch("isWhatsAppSame");

  const handleOpenModal = (pdfFile) => {
    setPdfUrl(pdfFile); // Set the appropriate PDF URL
    setModalIsOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setModalIsOpen(false); // Close the modal
    setPdfUrl(""); // Clear the PDF URL
  };

  // Handle "Same as Mobile" checkbox change
  const handleWhatsAppSameChange = (e) => {
    if (e.target.checked) {
      setValue("whatsapp", mobileNumber);
    } else {
      setValue("whatsapp", "");
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const indianStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Lakshadweep",
    "Delhi",
    "Puducherry",
    "Ladakh",
    "Jammu and Kashmir",
  ];

  // Disability categories array including "Other"
  const disabilityOptions = [
    { id: "blindness", label: "Blindness and Low Vision" },
    { id: "deaf", label: "Deaf and Hard of Hearing" },
    {
      id: "locomotor & cerebral palsy",
      label: "Locomotor Disability including cerebral palsy",
    },
    { id: "intellectual", label: "Intellectual Disability" },
    { id: "multiple", label: "Multiple Disabilities" },
    { id: "other", label: "Other (Please specify)" }, // "Other" option added
  ];

  const handleDisabilityChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedDisabilities((prev) => [...prev, value]);
    } else {
      setSelectedDisabilities((prev) => prev.filter((item) => item !== value));
    }
  };

  // Regular expressions
  const aparIdRegex = /^\d{12}$/;
  const aicteIdRegex = /^STU[0-9a-zA-Z]{13,}$/;

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await profileGetDataService.profileData(); // Fetch the data from your service
        const data = response?.data;
        console.log(response?.data);

        populateFormFields(data, setValue);

        // if (data?.profile_update_status === 1) {
        //   navigate("/internship");
        //   window.location.reload();
        //   return; // Don't continue with setting the profile form data
        // }

        if (data?.aicte_verified === "1") {
          setIsAicteVerified(true);
          // setIsPreVerified(true);
        }

        if (data?.aicte_verified === "0") {
          setIsAicteVerified(false);
          // setIsPreVerified(true);
        }

        // Populate form fields with the initial data
        // setValue("first_name", data?.first_name);
        // setValue("middle_name", data.middle_name);
        // setValue("last_name", data.last_name);
        // setValue("email", data.email);
        // setValue("mobile", data.mobile);
        // setValue("whatsapp", data.whatsapp);
        // setValue("dob", data.dob);
        // setValue("gender", data.gender);
        // setValue("caste", data.caste);
        // setValue("disability", data.disability);
        // setValue("state_id", data.state_id);
        // setValue("district", data.district);
        // setValue("city", data.city);
        // setValue("pincode", data.pincode);
        // setValue("address", data.address);
        // setValue("institute_id", data.institute_id);
        // setValue("tenth_mark", data.tenth_mark);
        // setValue("tenthPassoutYear", data.tenthPassoutYear);
        // setValue("intermediate_qualification", data.intermediate_qualification);
        // setValue("twth_mark", data.twth_mark);
        // setValue("roll_no", data.roll_no);
        // setValue("aicteId", data.aicte_id);
        // setValue("btech_cgpa", data.btech_cgpa);
        // setValue("branch", data.branch);
        // setValue("university", data.university);
        // setIsAlumni(data?.is_alumini);
        // setUpdateStatus(data?.profile_update_status);
        setIsLoading(false);
        // Set other fields as needed
      } catch (error) {
        console.error("Failed to fetch profile data:", error);
        setIsLoading(false); // In case of error, stop loading
      }
    };

    fetchProfileData();
  }, [setValue, navigate]); // Run once on component mount

  const disableRightClick = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (isAlumni === "1") {
      setShowAlumniMessage(true);
    }
  }, [isAlumni]);

  // Handle close for alumni message modal
  const handleCloseAlumniMessage = () => {
    setShowAlumniMessage(false);
  };

  //   if (updateStatus === 1) {
  //     navigate("/internship");
  //   }

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await getPrograms.programsData(); // Fetch programs
        setPrograms(response.data); // Assuming response.data contains the array of programs
        console.log("Programs Data: ", response.data);
      } catch (error) {
        console.error("Failed to fetch programs", error);
      }
    };

    fetchPrograms();
  }, []);

  // Handle course selection to display branches
  const handleProgramChange = (e) => {
    const programId = e.target.value;
    const selectedProgramData = programs.find(
      (p) => String(p.program_id) === programId
    );

    console.log(selectedProgramData);

    if (selectedProgramData) {
      setBranches(selectedProgramData.branches);
      setSelectedProgramDuration(selectedProgramData.duration); // Store the program duration
      setValue("program_id", programId);
      setShowBranchFields(false); // Reset branch-related fields
    } else {
      setBranches([]);
      setSelectedProgramDuration(null);
      setShowBranchFields(false);
    }
  };

  // Update UG program duration and branches when a UG course is selected
  const handleUGProgramChange = (e) => {
    const programId = e.target.value;
    const selectedProgramData = programs.find(
      (p) => String(p.program_id) === programId
    );

    if (selectedProgramData) {
      setUGBranches(selectedProgramData.branches);
      setValue("program_id_ug", programId);
      setSelectedUGProgramDuration(selectedProgramData.duration); // Store the UG program duration
      setShowUGBranchFields(true);
    } else {
      setUGBranches([]);
      setSelectedUGProgramDuration(null);
      setShowUGBranchFields(false);
    }
  };
  const handlePGProgramChange = (e) => {
    const programId = e.target.value;
    const selectedProgramData = programs.find(
      (p) => String(p.program_id) === programId
    );

    if (selectedProgramData) {
      setPGBranches(selectedProgramData.branches);
      setValue("program_id_pg", programId);
      setSelectedPGProgramDuration(selectedProgramData.duration); // Store the PG program duration
      setShowPGBranchFields(true);
    } else {
      setPGBranches([]);
      setSelectedPGProgramDuration(null);
      setShowPGBranchFields(false);
    }
  };

  // Handle branch selection to display Roll No. and University Regd. No.
  const handleBranchChange = (e) => {
    if (e.target.value) {
      setShowBranchFields(true);
    } else {
      setShowBranchFields(false);
    }
  };

  const validateFileSize = (file) => {
    if (file && file.size > MAX_FILE_SIZE) {
      return `File size exceeds 1MB limit (Current size: ${(
        file.size /
        (1024 * 1024)
      ).toFixed(2)}MB)`;
    }
    return true;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type !== "application/pdf") {
        event.target.value = null; // Clear the file input
        toast.error("Please upload only PDF files");
        return;
      }

      const validationResult = validateFileSize(file);
      if (validationResult !== true) {
        event.target.value = null; // Clear the file input
        toast.error(validationResult);
        return;
      }

      // If file passes all validations, proceed with registration
      register("aicte_certificate").onChange(event);
      setIsAicteVerified(false); // Reset verification when new file is uploaded
    }
  };

  const getVerificationMessage = (details) => {
    // If all statuses are true, return a success message
    if (details.nstatus && details.aictestatus) {
      return {
        message: "Verification successful: All details have been verified.",
        type: "success",
      };
    }

    // If all statuses are false, return a specific error message
    if (!details.nstatus && !details.aictestatus) {
      return {
        message:
          "Error: All verification checks failed. Please provide valid details.",
        type: "error",
      };
    }

    // If any status is false, collect the failed verifications
    const failedVerifications = [];
    if (!details.nstatus) {
      failedVerifications.push(
        "Name verification failed: Please check the entered name."
      );
    }
    if (!details.aictestatus) {
      failedVerifications.push(
        "AICTE ID verification failed: Please ensure the ID is correct."
      );
    }
    // if (!details.institutestatus) {
    //   failedVerifications.push(
    //     "Institute verification failed: Verify the institute details provided."
    //   );
    // }

    // Return error message and type
    return {
      message: failedVerifications.join("; "),
      type: "error",
    };
  };

  const verifyAicteCertificate = async () => {
    setIsVerifying(true);
    setVerificationError("");
    setVerificationDetails({
      nstatus: false,
      aictestatus: false,
      // institutestatus: false,
    });

    try {
      const certificateFile = watch("aicte_certificate")?.[0];
      const aicteId = watch("aicteId")?.trim();
      const firstName = watch("first_name")?.trim() || "";
      const middleName = watch("middle_name")?.trim() || "";
      const lastName = watch("last_name")?.trim() || "";

      // Validate required fields
      if (!certificateFile || !aicteId || !firstName || !lastName) {
        let errorMessage = "Missing required fields:";
        if (!certificateFile) errorMessage += "\n- AICTE profile PDF";
        if (!aicteId) errorMessage += "\n- AICTE ID";
        if (!firstName) errorMessage += "\n- First Name";
        if (!lastName) errorMessage += "\n- Last Name";
        throw new Error(errorMessage);
      }

      // Validate AICTE ID format
      if (!aicteId.match(/^STU[0-9a-zA-Z]{13,}$/)) {
        throw new Error(
          "Invalid AICTE ID format. It should start with 'STU' followed by at least 13 alphanumeric characters"
        );
      }

      // Double-check file size before sending
      const validationResult = validateFileSize(certificateFile);
      if (validationResult !== true) {
        throw new Error(validationResult);
      }

      const formData = new FormData();
      formData.append("file", certificateFile);
      formData.append("first_name", firstName);
      formData.append("middle_name", middleName);
      formData.append("last_name", lastName);
      formData.append("aicte", aicteId);

      const token = localStorage.getItem("accessToken");

      const response = await axios.post(
        `${BASE_URL}/profile/validation`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        const { nstatus, aictestatus } = response.data;
        setVerificationDetails({ nstatus, aictestatus });

        const verificationResult = getVerificationMessage({
          nstatus,
          aictestatus,
        });

        if (verificationResult.type === "success") {
          setIsAicteVerified(true);
          toast.success(
            "Verification successful: All details have been verified."
          );
        } else {
          setIsAicteVerified(false);
          throw new Error(verificationResult.message);
        }
      } else {
        throw new Error(
          "Verification failed. Please check your certificate, AICTE ID, and name details."
        );
      }
    } catch (error) {
      setIsAicteVerified(false);
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "Verification failed. Please try again.";
      setVerificationError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setIsVerifying(false);
    }
  };

  const ugAdmissionYear = watch("year_of_addmission_ug");
  // const calculateUGPassoutYear = () => {
  //   if (ugAdmissionYear && selectedProgramDuration) {
  //     const passoutYear =
  //       parseInt(ugAdmissionYear) + parseInt(selectedProgramDuration);
  //     setIsAlumni(passoutYear <= 2024);
  //     return passoutYear;
  //   }
  //   return "";
  // };

  // Calculate UG Passout Year whenever `ugAdmissionYear` or `selectedUGProgramDuration` changes
  useEffect(() => {
    if (ugAdmissionYear && selectedUGProgramDuration) {
      const calculatedYear =
        parseInt(ugAdmissionYear) + parseInt(selectedUGProgramDuration);
      setUGPassoutYear(calculatedYear);
      setIsAlumni(calculatedYear <= 2024); // This line ensures Alumni status is only checked when needed
    } else {
      setUGPassoutYear(""); // Clear if dependencies are missing
    }
  }, [ugAdmissionYear, selectedUGProgramDuration]);

  const pgAdmissionYear = watch("year_of_addmission_pg");
  // Calculate PG Passout Year whenever `pgAdmissionYear` or `selectedPGProgramDuration` changes
  useEffect(() => {
    if (pgAdmissionYear && selectedPGProgramDuration) {
      setPGPassoutYear(
        parseInt(pgAdmissionYear) + parseInt(selectedPGProgramDuration)
      );
    } else {
      setPGPassoutYear(""); // Clear if dependencies are missing
    }
  }, [pgAdmissionYear, selectedPGProgramDuration]);
  // const calculatePGPassoutYear = () => {
  //   if (pgAdmissionYear && selectedProgramDuration) {
  //     return parseInt(pgAdmissionYear) + parseInt(selectedProgramDuration);
  //   }
  //   return "";
  // };

  // Add keyframes animation for the blinking effect
  const blinkAnimation = `
    @keyframes blink {
      0%, 100% { opacity: 1; }
      50% { opacity: 0.4; }
    }
  `;

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    try {
      // Function to convert the file to a Base64 string
      const toBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result.split(",")[1]); // Get only the Base64 part
          reader.onerror = (error) => reject(error);
        });
      };

      // Check if a file is uploaded
      let profilePicBase64 = null;
      if (data.profile_pic && data.profile_pic.length > 0) {
        profilePicBase64 = await toBase64(data.profile_pic[0]); // Convert to Base64
      }

      // Prepare the payload
      const payload = {
        ...data,
        profile_pic: profilePicBase64,
        ug_passout_year: ugPassoutYear.toString(), // Include UG passout year
        pg_passout_year: pgPassoutYear.toString(), // Include PG passout year
        mobile: String(data.mobile),
        // job_status: jobStatus,
        // reason_for_no_job: reasonForNoJob, // Add Base64 string of the file to the payload
      };

      // Conditionally include job details only if isAlumni is "1"
      if (isAlumni === "1") {
        payload.job_status = jobStatus;
        payload.reason_for_no_job = reasonForNoJob;
      }

      // Convert selected disabilities to a string
      let disabilityString = selectedDisabilities.join(", ");

      // If "Other" is selected, append the specified disability
      if (selectedDisabilities.includes("other") && data.otherDisability) {
        disabilityString += `, ${data.otherDisability}`;
      }

      // Add the disability string to the payload
      payload.disability_types = disabilityString;

      // Send the payload using Axios or Fetch
      const response = await profileUpdateDataService.updateProfile(payload);

      if (response.status === 200) {
        toast.success("Profile updated successfully!");
        navigate("/internship");
        window.location.reload();
      } else {
        throw new Error("Failed to update profile");
      }
    } catch (error) {
      toast.error("Failed to update profile. Please try again.");
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Add this useEffect hook after your state declarations
  useEffect(() => {
    // Show tooltip for 6 seconds then hide
    const timer = setTimeout(() => {
      setShowInitialTooltip(false);
    }, 6000);

    // Cleanup timer
    return () => clearTimeout(timer);
  }, []);

  const LoadingIcon = () => (
    <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  );

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="bg-gray-100 min-h-screen p-4">
      <div className="max-w-8xl mx-auto p-4">
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
          {/* Personal Details Card */}
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="bg-gradient-to-r from-blue-800 to-blue-900 p-6">
              <h2 className="text-xl text-white font-semibold tracking-wide">
                Personal Details
              </h2>
            </div>

            <div className="p-6 space-y-8">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className="block mb-1 font-medium">
                    First Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    style={{ textTransform: "uppercase" }} // This makes it display in uppercase
                    {...register("first_name", { required: true })}
                    onChange={(e) => {
                      e.target.value = e.target.value.toUpperCase();
                    }}
                    readOnly={isAicteVerified}
                  />
                  {errors.first_name && (
                    <p className="text-red-500">First name is required</p>
                  )}
                </div>

                <div>
                  <label className="block mb-1 font-medium">Middle Name</label>
                  <input
                    style={{ textTransform: "uppercase" }}
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    {...register("middle_name")}
                    onChange={(e) => {
                      e.target.value = e.target.value.toUpperCase();
                    }}
                    readOnly={isAicteVerified}
                  />
                </div>

                <div>
                  <label className="block mb-1 font-medium">
                    Last Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    style={{ textTransform: "uppercase" }}
                    {...register("last_name", { required: true })}
                    onChange={(e) => {
                      e.target.value = e.target.value.toUpperCase();
                    }}
                    readOnly={isAicteVerified}
                  />
                  {errors.last_name && (
                    <p className="text-red-500">Last name is required</p>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                <div>
                  <label className="block mb-1 font-medium">
                    Email ID <span className="text-red-500">*</span>
                  </label>
                  <input
                    className={`w-full border p-2 rounded-lg ${
                      errors.email ? "border-red-500" : "border-gray-300"
                    }`}
                    type="email"
                    readOnly
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Please enter a valid email address",
                      },
                    })}
                  />
                  {errors.email && (
                    <p className="text-red-500">{errors.email.message}</p>
                  )}
                </div>

                <div>
                  <label className="block mb-1 font-medium">
                    Contact No. <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    type="text" // Ensure the input type is text to handle numbers with leading zeros
                    {...register("mobile", {
                      required: "Mobile number is required",
                      pattern: {
                        value: /^[6-9]\d{9}$/, // Validate the mobile number pattern
                        message: "Invalid mobile number format",
                      },
                    })}
                  />
                  {errors.mobile && (
                    <p className="text-red-500">{errors.mobile.message}</p>
                  )}
                </div>

                <div>
                  <label className="block mb-1 font-medium">
                    WhatsApp No.<span className="text-red-500">*</span>
                  </label>

                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    type="text"
                    {...register("whatsapp", {
                      required:
                        !isWhatsAppSame && "WhatsApp number is required", // Required only if not disabled
                      pattern: {
                        value: /^[6-9]\d{9}$/,
                        message: "Invalid WhatsApp number format",
                      },
                    })}
                    disabled={isWhatsAppSame} // Disable input if checkbox is checked
                  />
                  {errors.whatsapp && (
                    <p className="text-red-500">{errors.whatsapp.message}</p>
                  )}
                  {/* Checkbox for Same as Mobile - Placed directly under WhatsApp label */}
                  <div className="flex items-center mt-1 ml-2">
                    <input
                      type="checkbox"
                      {...register("isWhatsAppSame")}
                      onChange={handleWhatsAppSameChange}
                    />
                    <label className="ml-2 font-medium">
                      Same as contact number
                    </label>
                  </div>
                </div>
              </div>

              {/* Checkbox for Same as Mobile */}

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                <div>
                  <label className="block mb-1 font-medium">
                    AICTE Internship ID <span className="text-red-500">*</span>
                    <IconButton onClick={() => handleOpenModal(aiml)}>
                      <InfoIcon size={21} />
                    </IconButton>
                  </label>

                  <div className="flex items-center gap-2">
                    <input
                      className="flex-1 border border-gray-300 p-2 rounded-lg"
                      type="text"
                      {...register("aicteId", {
                        required: "AICTE ID is required",
                        pattern: {
                          value: aicteIdRegex,
                          message: "Invalid AICTE Internship ID",
                        },
                      })}
                      onChange={(e) => {
                        const trimmedValue = e.target.value.trim();
                        setValue("aicteId", trimmedValue, {
                          shouldValidate: true,
                        });
                      }}
                      readOnly={isAicteVerified}
                    />
                    {!showPdfSection ? (
                      <button
                        type="button"
                        onClick={() => setShowPdfSection(true)}
                        className={`px-4 py-2 rounded-lg ${
                          isAicteVerified
                            ? "bg-green-600 hover:bg-green-700"
                            : "bg-blue-600 hover:bg-blue-700"
                        } text-white whitespace-nowrap transition-colors ${
                          !watch("aicteId")
                            ? "opacity-50 cursor-not-allowed"
                            : ""
                        }`}
                        disabled={!watch("aicteId") || isAicteVerified}
                      >
                        {isAicteVerified ? (
                          <span className="flex items-center gap-2">
                            <svg
                              className="w-5 h-5"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              />
                            </svg>
                            Verified
                          </span>
                        ) : (
                          "Verify"
                        )}
                      </button>
                    ) : null}
                  </div>

                  {errors.aicteId && (
                    <p className="text-red-500 mt-1">
                      {errors.aicteId.message}
                    </p>
                  )}
                  {/* Beautiful Note Section */}
                  <div className="mt-3 relative">
                    <div className="bg-gradient-to-r from-blue-50 to-indigo-50 border-l-4 border-blue-500 rounded-lg p-4 shadow-sm">
                      <div className="flex items-start">
                        <div className="flex-shrink-0">
                          <AlertCircle className="h-5 w-5 text-blue-500" />
                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-blue-800">
                            Important Note:
                          </h3>
                          <div className="mt-1">
                            <p className="text-sm text-blue-700 leading-relaxed">
                              AICTE ID verification is mandatory.
                            </p>
                            <ul className="mt-1 space-y-1 text-sm text-blue-700 list-disc list-inside">
                              <li>Enter your complete AICTE ID</li>
                              <li>
                                Click "Verify" to complete the verification
                                process
                              </li>
                              <li>
                                Submit your AICTE profile PDF in the next step
                              </li>
                            </ul>
                          </div>
                          <div className="mt-2 flex items-center">
                            <div className="flex-shrink-0">
                              <span className="inline-flex items-center justify-center h-6 w-6 rounded-full bg-blue-100">
                                <svg
                                  className="h-4 w-4 text-blue-600"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                </svg>
                              </span>
                            </div>
                            <p className="ml-2 text-sm text-blue-600 font-medium">
                              Your profile will not be complete without
                              verification
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* Animated Arrow when AICTE ID is entered but not verified */}
                      {/* {watch("aicteId") && !showPdfSection && (
                      <div className="absolute -right-6 top-1/2 transform -translate-y-1/2">
                        <div
                          className="animate-bounce"
                          style={{
                            animation: "bounce 1s infinite",
                          }}
                        >
                          <svg
                            className="h-12 w-12 text-blue-500"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path d="M13 7l5 5m0 0l-5 5m5-5H6"></path>
                          </svg>
                        </div>
                      </div>
                    )} */}
                    </div>
                  </div>
                </div>

                {showPdfSection && (
                  <div>
                    <style>{blinkAnimation}</style>
                    <label className="block mb-1 font-medium">
                      <div className="inline-flex items-center">
                        AICTE Profile PDF{" "}
                        <span className="text-red-500">*</span>
                        <Tooltip
                          title="Click for AICTE Profile PDF information"
                          arrow
                          // open={showInitialTooltip}
                          placement="right"
                          sx={{
                            zIndex: 1, // Lower z-index if gradient is part of a higher layer
                          }}
                        >
                          <button
                            type="button"
                            onClick={() => setOpenAicteModal(true)}
                            className="inline-flex items-center gap-2 px-3 py-1 ml-2 text-sm font-medium text-white bg-red-500 rounded-full hover:bg-red-600 transition-colors animate-[blink_1.5s_ease-in-out_infinite]"
                            style={{
                              animation: "blink 1.5s ease-in-out infinite",
                              boxShadow: "0 0 10px rgba(59, 130, 246, 0.5)",
                            }}
                          >
                            {/* <InfoOutlinedIcon fontSize="small" /> */}
                            HOW TO GET THE PDF
                          </button>
                        </Tooltip>
                      </div>
                    </label>

                    <div className="flex items-center gap-2">
                      <div className="relative flex-1">
                        <div
                          className={`relative border-2 border-dashed rounded-lg p-4 text-center 
              ${
                errors.aicte_certificate ? "border-red-500" : "border-gray-300"
              } 
              ${
                isAicteVerified
                  ? "border-green-500 bg-green-50"
                  : "hover:bg-gray-50"
              } 
              transition-colors`}
                        >
                          {/* File input - Only render if not verified */}
                          {!isAicteVerified && (
                            <input
                              type="file"
                              accept="application/pdf"
                              className="absolute inset-0 w-full h-full opacity-0 z-10 cursor-pointer"
                              {...register("aicte_certificate", {
                                validate: {
                                  fileType: (value) =>
                                    !value[0] ||
                                    value[0]?.type === "application/pdf" ||
                                    "Only PDF files are allowed",
                                  fileSize: (value) =>
                                    !value[0] ||
                                    validateFileSize(value[0]) === true ||
                                    validateFileSize(value[0]),
                                },
                              })}
                              onChange={(e) => {
                                const file = e.target.files[0];
                                if (file) {
                                  if (file.type !== "application/pdf") {
                                    e.target.value = null;
                                    toast.error("Please upload only PDF files");
                                    return;
                                  }
                                  const validationResult =
                                    validateFileSize(file);
                                  if (validationResult !== true) {
                                    e.target.value = null;
                                    toast.error(validationResult);
                                    return;
                                  }
                                  register("aicte_certificate").onChange(e);
                                  setIsAicteVerified(false);
                                }
                              }}
                            />
                          )}

                          {/* Display content */}
                          {watch("aicte_certificate")?.[0] ? (
                            <div className="flex items-center justify-center gap-2">
                              <svg
                                className={`w-6 h-6 ${
                                  isAicteVerified
                                    ? "text-green-500"
                                    : "text-gray-400"
                                }`}
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                              >
                                {isAicteVerified ? (
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                ) : (
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                                  />
                                )}
                              </svg>
                              <span className="text-sm text-gray-600">
                                {watch("aicte_certificate")[0].name}
                              </span>
                            </div>
                          ) : (
                            <div>
                              {isAicteVerified ? (
                                <svg
                                  className="mx-auto h-8 w-8 text-green-500"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  className="mx-auto h-8 w-8 text-gray-400"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                  />
                                </svg>
                              )}
                              <div className="mt-2">
                                <span className="text-sm font-medium text-gray-900">
                                  {isAicteVerified
                                    ? "Document Verified"
                                    : "Click to upload"}
                                </span>{" "}
                                <span className="text-xs text-gray-600">
                                  {!isAicteVerified && "or drag and drop"}
                                </span>
                              </div>
                              <p className="mt-1 text-xs text-gray-500">
                                {!isAicteVerified && "PDF up to 1MB"}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Only show verify button when not verified */}
                      {!isAicteVerified && (
                        <button
                          type="button"
                          className={`px-4 py-2 rounded-lg flex items-center gap-2 ${
                            isVerifying
                              ? "bg-gray-400 cursor-not-allowed"
                              : "bg-blue-600 hover:bg-blue-700"
                          } text-white whitespace-nowrap transition-colors`}
                          onClick={verifyAicteCertificate}
                          disabled={
                            isVerifying ||
                            !watch("aicte_certificate")?.[0] ||
                            !watch("aicteId")
                          }
                        >
                          {isVerifying ? (
                            <>
                              <LoadingIcon />
                              <span>Verifying...</span>
                            </>
                          ) : (
                            <span>Verify</span>
                          )}
                        </button>
                      )}
                    </div>

                    {errors.aicte_certificate && (
                      <p className="mt-1 text-sm text-red-500">
                        {errors.aicte_certificate.message}
                      </p>
                    )}
                    {verificationError && (
                      <p className="text-red-500">{verificationError}</p>
                    )}

                    {/* Only show verification details if there are any statuses */}
                    {(verificationDetails.nstatus ||
                      verificationDetails.aictestatus) && (
                      <div className="mt-2 text-sm">
                        <h4 className="font-medium mb-1">
                          Verification Status:
                        </h4>
                        <ul className="list-none space-y-1">
                          <li
                            className={
                              verificationDetails.nstatus
                                ? "text-green-600"
                                : "text-red-600"
                            }
                          >
                            <span className="inline-block w-4 mr-2">
                              {verificationDetails.nstatus ? "✓" : "✗"}
                            </span>
                            {verificationDetails.nstatus
                              ? "Name verification completed successfully"
                              : "Name verification failed"}
                          </li>
                          <li
                            className={
                              verificationDetails.aictestatus
                                ? "text-green-600"
                                : "text-red-600"
                            }
                          >
                            <span className="inline-block w-4 mr-2">
                              {verificationDetails.aictestatus ? "✓" : "✗"}
                            </span>
                            {verificationDetails.aictestatus
                              ? "AICTE ID successfully verified"
                              : "AICTE ID verification failed"}
                          </li>
                          {/* <li
                          className={
                            verificationDetails.institutestatus
                              ? "text-green-600"
                              : "text-red-600"
                          }
                        >
                          <span className="inline-block w-4 mr-2">
                            {verificationDetails.institutestatus ? "✓" : "✗"}
                          </span>
                          {verificationDetails.institutestatus
                            ? "Institute verification completed successfully"
                            : "Institute verification failed"}
                        </li> */}
                        </ul>

                        {/* Overall Verification Message */}
                        <div
                          className={`mt-2 font-medium ${
                            isAicteVerified ? "text-green-600" : "text-red-600"
                          }`}
                        >
                          {isAicteVerified
                            ? "Verification process completed successfully"
                            : "Verification incomplete. Kindly address the above issues."}
                        </div>
                      </div>
                    )}

                    {/* Only show the note when not verified */}
                    {!isAicteVerified && (
                      <div className="max-w-lg mx-auto my-4">
                        <div className="bg-white rounded-lg shadow-md overflow-hidden">
                          {/* Header Bar */}
                          <div className="bg-red-500 px-4 py-2 flex items-center">
                            <AlertTriangle className="h-6 w-6 text-white" />
                            <span className="text-white font-medium ml-2">
                              File Upload Requirements
                            </span>
                          </div>

                          {/* Content */}
                          <div className="p-4 bg-gradient-to-b from-red-50 to-white">
                            <div className="space-y-3">
                              {/* Size Requirement */}
                              <div className="flex items-start">
                                <div className="h-2 w-2 rounded-full bg-red-500 mt-2 mr-2"></div>
                                <p className="text-sm text-gray-700">
                                  Maximum file size allowed is{" "}
                                  <span className="font-medium">1MB</span>
                                </p>
                              </div>

                              {/* Format Requirement */}
                              <div className="flex items-start">
                                <div className="h-2 w-2 rounded-full bg-red-500 mt-2 mr-2"></div>
                                <p className="text-sm text-gray-700">
                                  Only PDF files generated using the{" "}
                                  <span className="inline-block bg-red-100 text-red-700 px-2 py-0.5 rounded font-medium">
                                    'Save as PDF'
                                  </span>{" "}
                                  option will be accepted
                                </p>
                              </div>

                              {/* Invalid Formats */}
                              <div className="mt-3 pl-4">
                                <p className="text-sm text-red-600 italic">
                                  Screenshots or scanned copies will not be
                                  valid
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <div>
                  <label className="block mb-1 font-medium">
                    APAAR ID (Optional)
                    <IconButton onClick={() => handleOpenModal(apaar)}>
                      <InfoIcon size={21} />
                    </IconButton>
                  </label>
                  <div className="flex items-center">
                    <input
                      className="w-full border border-gray-300 p-2 rounded-lg"
                      type="text"
                      {...register("apar_id", {
                        pattern: {
                          value: aparIdRegex,
                          message:
                            "Invalid APAAR ID. Must be exactly 12 digits.",
                        },
                      })}
                    />
                  </div>
                  {errors.apar_id?.message && (
                    <p className="text-red-500">{errors.apar_id.message}</p>
                  )}
                </div>
              </div>

              {/* DOB, Gender, Category, Are you a person with any disability */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                <div>
                  <label className="block mb-1 font-medium">
                    Date of Birth <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    type="date"
                    {...register("dob", { required: true })}
                  />
                  {errors.dob && (
                    <p className="text-red-500">DOB is required</p>
                  )}
                </div>

                <div>
                  <label className="block mb-1 font-medium">
                    Gender <span className="text-red-500">*</span>
                  </label>
                  <select
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    {...register("gender", { required: true })}
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Transgender</option>
                  </select>
                  {errors.gender && (
                    <p className="text-red-500">Gender is required</p>
                  )}
                </div>

                <div>
                  <label className="block mb-1 font-medium">
                    Category <span className="text-red-500">*</span>
                  </label>
                  <select
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    {...register("caste", { required: true })}
                  >
                    <option value="GEN">GEN</option>
                    <option value="OBC">OBC</option>
                    <option value="SC">SC</option>
                    <option value="ST">ST</option>
                    <option value="NT">NT</option>
                  </select>
                  {errors.caste && (
                    <p className="text-red-500">Category is required</p>
                  )}
                </div>

                {/* Are you a person with any disability (Radio Button) */}
                {/* Are you a person with any disability (Radio Button) */}
                <div className="mt-4">
                  <label className="block mb-1 font-medium">
                    Are you a person with any disability?{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <div className="flex items-center space-x-4">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        value="yes"
                        {...register("is_pwd", { required: true })} // Use the same 'register' field
                        className="form-radio"
                        onClick={() => setShowDisabilityOptions(true)}
                      />
                      <span className="ml-2">Yes</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        value="no"
                        {...register("is_pwd", { required: true })} // Use the same 'register' field
                        className="form-radio"
                        onClick={() => setShowDisabilityOptions(false)}
                      />
                      <span className="ml-2">No</span>
                    </label>
                  </div>
                  {errors.is_pwd && (
                    <p className="text-red-500">
                      Please select if you have a disability
                    </p>
                  )}
                </div>
              </div>

              {/* Disability Categories (Checkboxes - Only show if "Yes" is selected) */}
              {disabilityStatus === "yes" && (
                <div className="mt-4">
                  <label className="block mb-2 font-medium">
                    If yes, please select applicable categories:
                  </label>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {disabilityOptions.map((option) => (
                      <label
                        key={option.id}
                        className="inline-flex items-center"
                      >
                        <input
                          type="checkbox"
                          value={option.id}
                          onChange={handleDisabilityChange}
                          className="form-checkbox"
                        />
                        <span className="ml-2">{option.label}</span>
                      </label>
                    ))}
                  </div>

                  {selectedDisabilities.includes("other") && (
                    <div className="mt-4">
                      <label className="block mb-2 font-medium">
                        Please specify your disability:{" "}
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        className="w-full border border-gray-300 p-2 rounded-lg"
                        {...register("otherDisability", {
                          required: selectedDisabilities.includes("other"),
                        })}
                      />
                      {errors.otherDisability && (
                        <p className="text-red-500">
                          Please specify your disability
                        </p>
                      )}
                    </div>
                  )}
                </div>
              )}

              <h1 className="text-xl font-bold mt-6 uppercase text-gray-700 px-2 py-2 mb-3 pb-2">
                Residential / Permanent Address :
              </h1>

              {/* Address, Family Annual Income */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                <div>
                  <label className="block mb-1 font-medium">
                    Address <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    type="text"
                    {...register("house_no", { required: true })}
                  />
                  {errors.house_no && (
                    <p className="text-red-500">Address is required</p>
                  )}
                </div>
                <div>
                  <label className="block mb-1 font-medium">
                    Village/Town/City <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    type="text"
                    {...register("city", { required: true })}
                  />
                  {errors.city && (
                    <p className="text-red-500">
                      Village/Town/City is required
                    </p>
                  )}
                </div>

                <div>
                  <label className="block mb-1 font-medium">
                    District <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    type="text"
                    {...register("street_name", { required: true })}
                  />
                  {errors.street_name && (
                    <p className="text-red-500">District is required</p>
                  )}
                </div>
                <div>
                  <label className="block mb-1 font-medium">
                    State <span className="text-red-500">*</span>
                  </label>
                  <select
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    {...register("state", { required: true })}
                  >
                    <option value="">Select State</option>
                    {indianStates.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                  {errors.state && (
                    <p className="text-red-500">State is required</p>
                  )}
                </div>
                <div>
                  <label className="block mb-1 font-medium">
                    Pincode <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    type="text"
                    {...register("pincode", {
                      required: true,
                      pattern: /^[0-9]{6}$/,
                    })}
                  />
                  {errors.pincode && errors.pincode.type === "required" && (
                    <p className="text-red-500">Pincode is required</p>
                  )}
                  {errors.pincode && errors.pincode.type === "pattern" && (
                    <p className="text-red-500">
                      Please enter a valid 6-digit pincode
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Academy Details Card */}
          {/* Academy Details Card */}
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="bg-gradient-to-r from-blue-800 to-blue-900 p-6">
              <h2 className="text-xl text-white font-semibold tracking-wide">
                Academy Details
              </h2>
            </div>

            <div className="p-6 space-y-8">
              <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
                {/* <div>
                <h2 className="text-xl font-bold border-b-2 text-gray-700">
                  10th Qualification Details:
                </h2>
              </div> */}
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                  <div>
                    <label className=" block  mb-1 font-medium">
                      Qualification Details{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      className="w-full border border-gray-300 p-2 rounded-lg"
                      type="text"
                      placeholder="10th Details:"
                      disabled
                    />
                  </div>
                  {/* Institute Name */}
                  <div>
                    <label className="block mb-1 font-medium">
                      School Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      className="w-full border border-gray-300 p-2 rounded-lg"
                      type="text"
                      {...register("school_name_tenth", { required: true })}
                    />
                    {errors.school_name_tenth && (
                      <p className="text-red-500">School Name is required</p>
                    )}
                  </div>

                  {/* 10th State */}
                  {/* <div>
                  <label className="block mb-1 font-medium">State</label>
                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    type="text"
                    {...register("tenth_state", { required: true })}
                  />
                  {errors.tenth_state && (
                    <p className="text-red-500">State is required</p>
                  )}
                </div> */}

                  {/* 10th Mark */}
                  <div>
                    <label className="block mb-1 font-medium">
                      10th Mark (%) <span className="text-red-500">*</span>
                    </label>
                    <input
                      className="w-full border border-gray-300 p-2 rounded-lg"
                      type="number"
                      {...register("tenth_mark", {
                        required: true,
                        min: 0,
                        max: 100,
                      })}
                    />
                    {errors.tenth_mark && (
                      <p className="text-red-500">
                        Please enter a valid percentage
                      </p>
                    )}
                  </div>

                  {/* 10th Passout Year */}
                  <div>
                    <label className="block mb-1 font-medium">
                      10th Passout Year <span className="text-red-500">*</span>
                    </label>
                    <select
                      className="w-full border border-gray-300 p-2 rounded-lg"
                      {...register("matric_passout_year", { required: true })}
                    >
                      <option value="">Select Year</option>
                      {Array.from(
                        { length: 30 },
                        (_, i) => new Date().getFullYear() - i
                      ).map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                    {errors.matric_passout_year && (
                      <p className="text-red-500">Passout year is required</p>
                    )}
                  </div>
                </div>

                {/* 10th Passout Year
              <div>
                <label className="block mb-1 font-medium">
                  10th Passout Year
                </label>
                <select
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  {...register("tenthPassoutYear", { required: true })}
                >
                  <option value="">Select Year</option>
                  {Array.from(
                    { length: 30 },
                    (_, i) => new Date().getFullYear() - i
                  ).map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                {errors.tenthPassoutYear && (
                  <p className="text-red-500">Passout year is required</p>
                )}
              </div> */}
              </div>

              <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4 border-b-2 pb-4 md:border-0 md:pb-0">
                {/* Select Intermediate Qualification Dropdown (col-1) */}
                <div className="col-span-1">
                  <label className="block mb-1 font-medium">
                    Intermediate Qualification{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <select
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    {...register("intermediate_qualification", {
                      required: true,
                    })}
                  >
                    <option value="">Select Intermediate Qualification</option>
                    <option value="12th">12th</option>
                    <option value="Diploma">Diploma</option>
                    <option value="ITI">ITI</option>
                  </select>
                  {errors.intermediate_qualification && (
                    <p className="text-red-500">Qualification is required</p>
                  )}
                </div>

                {/* Conditional Fields Based on Qualification (col-5) */}
                <div className="md:col-span-3">
                  {watch("intermediate_qualification") === "12th" && (
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      {/* Institute Name for 12th */}
                      <div>
                        <label className="block mb-1 font-medium">
                          Institute Name <span className="text-red-500">*</span>
                        </label>
                        <input
                          className="w-full border border-gray-300 p-2 rounded-lg"
                          type="text"
                          {...register("school_name_twlth", { required: true })}
                        />
                        {errors.school_name_twlth && (
                          <p className="text-red-500">
                            Institute Name is required
                          </p>
                        )}
                      </div>

                      {/* State for 12th */}
                      {/* <div>
                      <label className="block mb-1 font-medium">State</label>
                      <input
                        className="w-full border border-gray-300 p-2 rounded-lg"
                        type="text"
                        {...register("twth_state", { required: true })}
                      />
                      {errors.twth_state && (
                        <p className="text-red-500">State is required</p>
                      )}
                    </div> */}

                      {/* 12th Percentage */}
                      <div>
                        <label className="block mb-1 font-medium">
                          12th Percentage{" "}
                          <span className="text-red-500">*</span>
                        </label>
                        <input
                          className="w-full border border-gray-300 p-2 rounded-lg"
                          type="number"
                          {...register("twth_mark", {
                            required: true,
                            min: 0,
                            max: 100,
                          })}
                        />
                        {errors.twth_mark && (
                          <p className="text-red-500">
                            Please enter a valid percentage
                          </p>
                        )}
                      </div>

                      {/* 12th Passout Year */}
                      <div>
                        <label className="block mb-1 font-medium">
                          12th Passout Year{" "}
                          <span className="text-red-500">*</span>
                        </label>
                        <select
                          className="w-full border border-gray-300 p-2 rounded-lg"
                          {...register("intermediate_passout_year", {
                            required: true,
                          })}
                        >
                          <option value="">Select Year</option>
                          {Array.from(
                            { length: 30 },
                            (_, i) => new Date().getFullYear() - i
                          ).map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                        {errors.intermediate_passout_year && (
                          <p className="text-red-500">
                            Passout year is required
                          </p>
                        )}
                      </div>
                    </div>
                  )}

                  {watch("intermediate_qualification") === "Diploma" && (
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      {/* Institute Name for Diploma */}
                      <div>
                        <label className="block mb-1 font-medium">
                          Institute Name <span className="text-red-500">*</span>
                        </label>
                        <input
                          className="w-full border border-gray-300 p-2 rounded-lg"
                          type="text"
                          {...register("school_name_twlth", {
                            required: true,
                          })}
                        />
                        {errors.school_name_twlth && (
                          <p className="text-red-500">
                            Institute Name is required
                          </p>
                        )}
                      </div>

                      {/* State for Diploma */}
                      {/* <div>
                      <label className="block mb-1 font-medium">State</label>
                      <input
                        className="w-full border border-gray-300 p-2 rounded-lg"
                        type="text"
                        {...register("diploma_state", { required: true })}
                      />
                      {errors.diploma_state && (
                        <p className="text-red-500">State is required</p>
                      )}
                    </div> */}

                      {/* Diploma Percentage */}
                      <div>
                        <label className="block mb-1 font-medium">
                          Diploma Percentage{" "}
                          <span className="text-red-500">*</span>
                        </label>
                        <input
                          className="w-full border border-gray-300 p-2 rounded-lg"
                          type="number"
                          {...register("twth_mark", {
                            required: true,
                            min: 0,
                            max: 100,
                          })}
                        />
                        {errors.twth_mark && (
                          <p className="text-red-500">
                            Please enter a valid percentage
                          </p>
                        )}
                      </div>

                      {/* Diploma Passout Year */}
                      <div>
                        <label className="block mb-1 font-medium">
                          Diploma Passout Year{" "}
                          <span className="text-red-500">*</span>
                        </label>
                        <select
                          className="w-full border border-gray-300 p-2 rounded-lg"
                          {...register("intermediate_passout_year", {
                            required: true,
                          })}
                        >
                          <option value="">Select Year</option>
                          {Array.from(
                            { length: 30 },
                            (_, i) => new Date().getFullYear() - i
                          ).map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                        {errors.intermediate_passout_year && (
                          <p className="text-red-500">
                            Passout year is required
                          </p>
                        )}
                      </div>
                    </div>
                  )}

                  {watch("intermediate_qualification") === "ITI" && (
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      {/* Institute Name for ITI */}
                      <div>
                        <label className="block mb-1 font-medium">
                          Institute Name <span className="text-red-500">*</span>
                        </label>
                        <input
                          className="w-full border border-gray-300 p-2 rounded-lg"
                          type="text"
                          {...register("school_name_twlth", { required: true })}
                        />
                        {errors.school_name_twlth && (
                          <p className="text-red-500">
                            Institute Name is required
                          </p>
                        )}
                      </div>

                      {/* State for ITI */}
                      {/* <div>
                      <label className="block mb-1 font-medium">State</label>
                      <input
                        className="w-full border border-gray-300 p-2 rounded-lg"
                        type="text"
                        {...register("iti_state", { required: true })}
                      />
                      {errors.iti_state && (
                        <p className="text-red-500">State is required</p>
                      )}
                    </div> */}

                      {/* ITI Percentage */}
                      <div>
                        <label className="block mb-1 font-medium">
                          ITI Percentage <span className="text-red-500">*</span>
                        </label>
                        <input
                          className="w-full border border-gray-300 p-2 rounded-lg"
                          type="number"
                          {...register("mark_twlth", {
                            required: true,
                            min: 0,
                            max: 100,
                          })}
                        />
                        {errors.mark_twlth && (
                          <p className="text-red-500">
                            Please enter a valid percentage
                          </p>
                        )}
                      </div>

                      {/* ITI Passout Year */}
                      <div>
                        <label className="block mb-1 font-medium">
                          ITI Passout Year{" "}
                          <span className="text-red-500">*</span>
                        </label>
                        <select
                          className="w-full border border-gray-300 p-2 rounded-lg"
                          {...register("intermediate_passout_year", {
                            required: true,
                          })}
                        >
                          <option value="">Select Year</option>
                          {Array.from(
                            { length: 30 },
                            (_, i) => new Date().getFullYear() - i
                          ).map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                        {errors.intermediate_passout_year && (
                          <p className="text-red-500">
                            Passout year is required
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* <div className="bg-white shadow-lg rounded-lg p-6">
              <h2 className="text-xl uppercase font-semibold text-gray-700 bg-gray-300 px-2 py-2 mb-6 border-b-2 pb-2">
                UG Details
              </h2>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block mb-1 font-medium">
                    Select UG Course <span className="text-red-500">*</span>
                  </label>
                  <select
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    onChange={handleUGProgramChange}
                  >
                    <option value="">Select UG Course</option>
                    {programs.map((program) => (
                      <option
                        key={program.program_id}
                        value={program.program_id}
                      >
                        {program.program_name}
                      </option>
                    ))}
                  </select>
                  <input
                    type="hidden"
                    {...register("program_id_ug", { required: true })}
                  />
                  {errors.program_id_ug && (
                    <p className="text-red-500">
                      UG Course selection is required
                    </p>
                  )}
                </div>

                
                {showUGBranchFields && (
                  <div>
                    <label className="block mb-1 font-medium">
                      Select UG Branch <span className="text-red-500">*</span>
                    </label>
                    <select
                      className="w-full border border-gray-300 p-2 rounded-lg"
                      {...register("branch_ug", { required: true })}
                      onChange={(e) =>
                        handleBranchChange(e, setShowUGBranchFields)
                      }
                    >
                      <option value="">Select UG Branch</option>
                      {branches.map((branch, index) => (
                        <option key={index} value={branch}>
                          {branch}
                        </option>
                      ))}
                    </select>
                    {errors.branch_ug && (
                      <p className="text-red-500">UG Branch is required</p>
                    )}
                  </div>
                )}
              </div>

              
            </div> */}

              <h2 className="text-lg mb-2 mt-4 font-bold">UG:</h2>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                {/* New fields start here */}
                <div>
                  <label className="block mb-1 font-medium">
                    University Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    type="text"
                    {...register("university", { required: true })}
                  />
                  {errors.university && (
                    <p className="text-red-500">University Name is required</p>
                  )}
                </div>

                <div>
                  <label className="block mb-1 font-medium">
                    College Name<span className="text-red-500">*</span>
                  </label>
                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    type="text"
                    {...register("institute_id", { required: true })}
                  />
                  {errors.institute_id && (
                    <p className="text-red-500">College Name is required</p>
                  )}
                </div>

                <div>
                  <label className="block mb-1 font-medium">State</label>
                  <select
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    {...register("state_id")}
                  >
                    <option value="">Select State</option>
                    {indianStates.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                  {errors.state_id && (
                    <p className="text-red-500">State is required</p>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-8 gap-4 mt-4">
                {/* Select Course */}
                <div>
                  <label className="block mb-1 font-medium">
                    Select UG Course <span className="text-red-500">*</span>
                  </label>
                  <select
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    onChange={handleUGProgramChange}
                  >
                    <option value="">Select UG Course</option>
                    {programs.map((program) => (
                      <option
                        key={program.program_id}
                        value={program.program_id}
                      >
                        {program.program_name}
                      </option>
                    ))}
                  </select>
                  <input
                    type="hidden"
                    {...register("program_id_ug", { required: true })}
                  />
                  {errors.program_id_ug && (
                    <p className="text-red-500">
                      UG Course selection is required
                    </p>
                  )}
                </div>

                {/* Select Branch */}
                {showUGBranchFields && (
                  <div>
                    <label className="block mb-1 font-medium">
                      Select UG Branch <span className="text-red-500">*</span>
                    </label>
                    <select
                      className="w-full border border-gray-300 p-2 rounded-lg"
                      {...register("branch_ug", { required: true })}
                    >
                      <option value="">Select UG Branch</option>
                      {ugBranches.map((branch, index) => (
                        <option key={index} value={branch}>
                          {branch}
                        </option>
                      ))}
                    </select>
                    {errors.branch_ug && (
                      <p className="text-red-500">UG Branch is required</p>
                    )}
                  </div>
                )}

                <div>
                  <label className="block mb-1 font-medium">
                    Select Semester <span className="text-red-500">*</span>
                  </label>
                  <select
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    {...register("semister", { required: true })}
                  >
                    <option value="">Select Semester</option>
                    {semesters.map((semester, index) => (
                      <option key={index} value={semester}>
                        {semester}
                      </option>
                    ))}
                  </select>
                  {errors.semister && (
                    <p className="text-red-500">
                      Semester selection is required
                    </p>
                  )}
                </div>

                <div>
                  <label className="block mb-1 font-medium">
                    Roll No. <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    type="text"
                    {...register("roll_no", { required: true })}
                  />
                  {errors.roll_no && (
                    <p className="text-red-500">Roll No. is required</p>
                  )}
                </div>

                <div>
                  <label className="block mb-1 font-medium">
                    University Reg. No.<span className="text-red-500">*</span>
                  </label>
                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    type="text"
                    {...register("regd_no", { required: true })}
                  />
                  {errors.regd_no && (
                    <p className="text-red-500">
                      University Regd. No. is required
                    </p>
                  )}
                </div>

                <div>
                  <label className="block mb-1 font-medium">
                    CGPA <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    type="number"
                    step="0.01" // Allows decimal values
                    {...register("btech_cgpa", {
                      required: "CGPA is required",
                      min: {
                        value: 0,
                        message: "CGPA cannot be less than 0",
                      },
                      max: {
                        value: 10,
                        message: "CGPA cannot be more than 10",
                      },
                    })}
                  />
                  {errors.btech_cgpa && (
                    <p className="text-red-500">{errors.btech_cgpa.message}</p>
                  )}
                </div>

                <div>
                  <label className="block mb-1 font-medium">
                    Admission Year <span className="text-red-500">*</span>
                  </label>
                  <select
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    {...register("year_of_addmission_ug", { required: true })}
                  >
                    <option value="">
                      Select Admission Year{" "}
                      <span className="text-red-500">*</span>
                    </option>
                    {Array.from(
                      { length: 30 },
                      (_, i) => new Date().getFullYear() - i
                    ).map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                  {errors.year_of_addmission_ug && (
                    <p className="text-red-500">Admission Year is required</p>
                  )}
                </div>

                <div>
                  <label className="block mb-1 font-medium">
                    Passout Year (UG) <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    type="text"
                    value={ugPassoutYear} // Display calculated passout year for UG
                    readOnly
                  />
                </div>
                {/* Hidden input to send passout year for UG */}
                <input
                  type="hidden"
                  {...register("ug_passout_year")}
                  value={ugPassoutYear}
                />
              </div>

              {/* PG Section */}

              <h2 className="text-xl uppercase mt-4 font-semibold text-gray-700   py-2 mb-6 pb-2">
                PG:
              </h2>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                {/* New fields start here */}
                <div>
                  <label className="block mb-1 font-medium">
                    University Name
                  </label>
                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    type="text"
                    {...register("pg_institute_id")}
                  />
                  {errors.pg_institute_id && (
                    <p className="text-red-500">University Name is required</p>
                  )}
                </div>

                <div>
                  <label className="block mb-1 font-medium">College Name</label>
                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    type="text"
                    {...register("pg_college_name")}
                  />
                  {errors.pg_college_name && (
                    <p className="text-red-500">College Name is required</p>
                  )}
                </div>

                <div>
                  <label className="block mb-1 font-medium">State</label>
                  <select
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    {...register("pg_state_id")}
                  >
                    <option value="">Select State</option>
                    {indianStates.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                  {errors.pg_state_id && (
                    <p className="text-red-500">State is required</p>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-8 gap-4 mt-4">
                {/* Select PG Course */}
                <div>
                  <label className="block mb-1 font-medium">
                    Select PG Course
                  </label>
                  <select
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    onChange={handlePGProgramChange}
                  >
                    <option value="">Select PG Course</option>
                    {programs.map((program) => (
                      <option
                        key={program.program_id}
                        value={program.program_id}
                      >
                        {program.program_name}
                      </option>
                    ))}
                  </select>
                  <input type="hidden" {...register("program_id_pg")} />
                </div>

                {/* Select PG Branch */}
                {showPGBranchFields && (
                  <div>
                    <label className="block mb-1 font-medium">
                      Select PG Branch
                    </label>
                    <select
                      className="w-full border border-gray-300 p-2 rounded-lg"
                      {...register("branch_pg")}
                    >
                      <option value="">Select PG Branch</option>
                      {pgBranches.map((branch, index) => (
                        <option key={index} value={branch}>
                          {branch}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {/* Select Semester */}
                <div>
                  <label className="block mb-1 font-medium">
                    Select Semester
                  </label>
                  <select
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    {...register("semester_pg")}
                  >
                    <option value="">Select Semester</option>
                    {semesters.map((semester, index) => (
                      <option key={index} value={semester}>
                        {semester}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Roll No. */}
                <div>
                  <label className="block mb-1 font-medium">Roll No.</label>
                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    type="text"
                    {...register("roll_no_pg")}
                  />
                </div>

                {/* University Registration No. */}
                <div>
                  <label className="block mb-1 font-medium">
                    University Reg. No.
                  </label>
                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    type="text"
                    {...register("regd_no_pg")}
                  />
                </div>

                {/* CGPA */}
                <div>
                  <label className="block mb-1 font-medium">CGPA</label>
                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    type="number"
                    step="0.01" // Allows decimal values
                    {...register("pg_cgpa", {
                      min: {
                        value: 0,
                        message: "CGPA cannot be less than 0",
                      },
                      max: {
                        value: 10,
                        message: "CGPA cannot be more than 10",
                      },
                    })}
                  />
                  {errors.pg_cgpa && (
                    <p className="text-red-500">{errors.pg_cgpa.message}</p>
                  )}
                </div>

                {/* Admission Year */}
                <div>
                  <label className="block mb-1 font-medium">
                    Admission Year
                  </label>
                  <select
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    {...register("year_of_addmission_pg")}
                  >
                    <option value="">Select Admission Year</option>
                    {Array.from(
                      { length: 30 },
                      (_, i) => new Date().getFullYear() - i
                    ).map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Passout Year */}
                <div>
                  <label className="block mb-1 font-medium">
                    Passout Year (PG)
                  </label>
                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    type="text"
                    value={pgPassoutYear} // Display calculated passout year for PG
                    readOnly
                  />
                  <input
                    type="hidden"
                    {...register("pg_passout_year")}
                    value={pgPassoutYear}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="bg-gradient-to-r from-blue-800 to-blue-900 p-6">
              <h2 className="text-xl text-white font-semibold tracking-wide">
                Other Details
              </h2>
            </div>
            <div className="p-6 space-y-8">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                <div>
                  <label className="block mb-1 font-medium">
                    Family Annual Income <span className="text-red-500">*</span>
                  </label>
                  <select
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    {...register("family_income", { required: true })}
                  >
                    <option value="">Select Income</option>
                    <option value="below 2 Lakh">{"<"} Below 2 Lakh</option>
                    <option value=">above 2 Lakh">{">"} Above 2 Lakh</option>
                  </select>
                  {errors.family_income && (
                    <p className="text-red-500">
                      Family Annual Income is required
                    </p>
                  )}
                </div>
                {/* Profile Photo Upload */}
                <div>
                  <label className="block mb-1 font-medium">
                    Upload Profile Photo <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    {...register("profile_pic", {
                      required: "Profile photo is required",
                      validate: {
                        fileType: (value) =>
                          (value &&
                            ["image/jpeg", "image/jpg", "image/png"].includes(
                              value[0]?.type
                            )) ||
                          "Invalid file type. Only JPG, JPEG, PNG are allowed.",
                        fileSize: (value) =>
                          (value && value[0]?.size <= 1024 * 1024) ||
                          "File size should be less than 1 MB.",
                      },
                    })}
                  />
                  {errors.profile_pic && (
                    <p className="text-red-500">{errors.profile_pic.message}</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div>
            {/* Job/Professional Details Section */}
            {isAlumni === "1" && (
              <div className="bg-white shadow-lg rounded-lg p-6 mt-6">
                <h2 className="text-xl uppercase font-semibold text-gray-700 bg-gray-300 px-2 py-2 mb-6 border-b-2 pb-2">
                  Job/Professional Details
                </h2>

                {/* Are you got a job? */}
                <div className="grid grid-cols-1 md:grid-cols-6 gap-4">
                  <label className="block mb-1 font-medium">
                    Have you got a job? <span className="text-red-500">*</span>
                  </label>
                  <div className="flex items-center">
                    <label className="mr-4">
                      <input
                        type="radio"
                        value="yes"
                        {...register("is_got_job", { required: true })}
                        onClick={() => setJobStatus("yes")}
                      />{" "}
                      Yes
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="no"
                        {...register("is_got_job", { required: true })}
                        onClick={() => setJobStatus("no")}
                      />{" "}
                      No
                    </label>
                  </div>
                  {errors.is_got_job && (
                    <p className="text-red-500">
                      Please select your job status
                    </p>
                  )}
                </div>

                {/* If 'Yes' is selected, show the company name, designation, and package fields */}
                {jobStatus === "yes" && (
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                    <div>
                      <label className="block mb-1 font-medium">
                        Company Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full border border-gray-300 p-2 rounded-lg"
                        type="text"
                        {...register("company_name", {
                          required: jobStatus === "yes",
                        })}
                      />
                      {errors.company_name && (
                        <p className="text-red-500">Company Name is required</p>
                      )}
                    </div>

                    <div>
                      <label className="block mb-1 font-medium">
                        Designation <span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full border border-gray-300 p-2 rounded-lg"
                        type="text"
                        {...register("designation", {
                          required: jobStatus === "yes",
                        })}
                      />
                      {errors.designation && (
                        <p className="text-red-500">Designation is required</p>
                      )}
                    </div>

                    <div>
                      <label className="block mb-1 font-medium">
                        Annual Package <span className="text-red-500">*</span>
                      </label>
                      <select
                        className="w-full border border-gray-300 p-2 rounded-lg"
                        {...register("annual_package", {
                          required: jobStatus === "yes",
                        })}
                      >
                        <option value="">Select Annual Package</option>
                        <option value="1-2 LPA">1-2 LPA</option>
                        <option value="2-4 LPA">2-4 LPA</option>
                        <option value="4-6 LPA">4-6 LPA</option>
                        <option value="6-8 LPA">6-8 LPA</option>
                        <option value="8-10 LPA">8-10 LPA</option>
                        <option value="10-12 LPA">10-12 LPA</option>
                        <option value="12-15 LPA">12-15 LPA</option>
                        <option value="15-20 LPA">15-20 LPA</option>
                        <option value="20 LPA and above">
                          20 LPA and above
                        </option>
                      </select>
                      {errors.annual_package && (
                        <p className="text-red-500">
                          Annual Package is required
                        </p>
                      )}
                    </div>
                  </div>
                )}

                {/* If 'No' is selected, show the reason options */}
                {jobStatus === "no" && (
                  <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4">
                    <label className="block mb-1 font-medium">
                      Reason for no job <span className="text-red-500">*</span>
                    </label>
                    <div className="flex flex-col">
                      <label>
                        <input
                          type="radio"
                          value="entrepreneur"
                          {...register("no_job_reason", {
                            required: jobStatus === "no",
                          })}
                          onClick={() => setReasonForNoJob("entrepreneur")}
                        />{" "}
                        Entrepreneur
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="higher_study"
                          {...register("no_job_reason", {
                            required: jobStatus === "no",
                          })}
                          onClick={() => setReasonForNoJob("higher_study")}
                        />{" "}
                        Higher Study
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="searching_job"
                          {...register("no_job_reason", {
                            required: jobStatus === "no",
                          })}
                          onClick={() => setReasonForNoJob("searching_job")}
                        />{" "}
                        Searching for Job
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="other"
                          {...register("no_job_reason", {
                            required: jobStatus === "no",
                          })}
                          onClick={() => setReasonForNoJob("other")}
                        />{" "}
                        Other
                      </label>
                    </div>

                    {/* Show text area for explanation only if "Other" is selected */}
                    {reasonForNoJob === "other" && (
                      <textarea
                        className="w-full border border-gray-300 p-2 rounded-lg mt-2"
                        placeholder="Please provide details"
                        {...register("no_job_details", {
                          required: reasonForNoJob === "other", // Only required if "Other" is selected
                          minLength: 10,
                        })}
                      ></textarea>
                    )}
                    {errors.no_job_details && (
                      <p className="text-red-500">
                        Please provide details (minimum 10 characters)
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>

          <Modal
            open={modalIsOpen}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box sx={style} onContextMenu={(e) => e.preventDefault()}>
              <h2 id="modal-title" className="text-lg font-semibold">
                Information PDF
              </h2>
              {pdfUrl && (
                <embed
                  src={`${pdfUrl}#toolbar=0`}
                  type="application/pdf"
                  width="100%"
                  height="400px"
                />
              )}
              <button
                className="mt-4 bg-red-600 text-white py-2 px-4 rounded-lg"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </Box>
          </Modal>

          {/* <Modal
            open={modalIsOpen}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box sx={style}>
              <h2 id="modal-title" className="text-lg font-semibold">
                Information PDF
              </h2>
              {pdfUrl && (
                <>
                  <Document
                    file={pdfUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                    options={{
                      workerSrc: `https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js`,
                    }}
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        scale={1} // Adjust scale to fit modal better
                        className="pdf-page" // Apply custom class for styling
                      />
                    ))}
                  </Document>
                </>
              )}
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseModal}
                className="mt-4"
              >
                Close
              </Button>
            </Box>
          </Modal> */}

          <Modal open={showAlumniMessage} onClose={handleCloseAlumniMessage}>
            <Paper sx={style}>
              {/* Header */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography
                  id="modal-title"
                  variant="h5"
                  component="h2"
                  sx={{
                    color: "primary.main",
                    fontWeight: "bold",
                  }}
                >
                  Important Notice
                </Typography>
                <IconButton
                  aria-label="close"
                  onClick={handleCloseAlumniMessage}
                  sx={{
                    color: "grey.500",
                    "&:hover": {
                      color: "grey.700",
                      bgcolor: "grey.100",
                    },
                  }}
                >
                  <X />
                </IconButton>
              </Box>

              {/* Content */}
              <Alert
                severity="warning"
                icon={<WarningAmberIcon />}
                sx={{
                  mb: 3,
                  "& .MuiAlert-message": {
                    width: "100%",
                  },
                }}
              >
                <AlertTitle sx={{ fontWeight: "medium" }}>
                  Alumni Access Notice
                </AlertTitle>
                <Typography variant="body1">
                  If you are not an Alumni, please contact your College Spoc for
                  updating your final year.
                </Typography>
              </Alert>

              {/* Footer */}
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  onClick={handleCloseAlumniMessage}
                  sx={{
                    textTransform: "none",
                    px: 3,
                    "&:hover": {
                      bgcolor: "primary.dark",
                    },
                  }}
                >
                  Close
                </Button>
              </Box>
            </Paper>
          </Modal>

          <Modal
            open={openAicteModal}
            onClose={() => setOpenAicteModal(false)}
            aria-labelledby="aicte-modal-title"
            aria-describedby="aicte-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "90%",
                maxWidth: "800px",
                bgcolor: "background.paper",
                boxShadow: 24,
                borderRadius: "8px",
                height: "90vh",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Fixed Header */}
              <div className="p-4 border-b border-gray-200 flex justify-between items-center bg-white sticky top-0 z-10 rounded-t-lg">
                <Typography
                  variant="h5"
                  component="h2"
                  className="font-bold text-gray-800"
                >
                  Scroll down to know how to download AICTE Profile PDF
                </Typography>
                <IconButton
                  onClick={() => setOpenAicteModal(false)}
                  size="small"
                  aria-label="close"
                >
                  <X className="w-6 h-6" />
                </IconButton>
              </div>

              {/* Scrollable Content */}
              <div className="flex-1 overflow-y-auto px-4 py-6 space-y-6 custom-scrollbar">
                {/* New Alert Message */}
                <div className="mb-6">
                  <Alert
                    severity="warning"
                    className="bg-yellow-50 border-2 border-yellow-200 rounded-lg shadow-md"
                  >
                    <div className="flex items-center space-x-3">
                      <ShieldCheck className="text-yellow-600 w-6 h-6" />
                      <div>
                        <AlertTitle className="text-yellow-800 font-bold text-lg">
                          AICTE ID Verification is Mandatory
                        </AlertTitle>
                      </div>
                    </div>
                  </Alert>
                </div>

                <style>
                  {`
          .custom-scrollbar::-webkit-scrollbar {
            width: 8px;
          }
          .custom-scrollbar::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 4px;
          }
          .custom-scrollbar::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 4px;
          }
          .custom-scrollbar::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        `}
                </style>

                {/* Sample Image */}
                {/* <div className="mb-8 flex justify-center">
                  <img
                    src={aictesample}
                    alt="AICTE Portal Screenshot"
                    className="rounded-lg shadow-lg"
                  />
                </div> */}

                {/* Steps */}
                <div className="space-y-6">
                  {[
                    {
                      title: "Login to AICTE Portal",
                      description:
                        "Visit the AICTE Internship portal and sign in with your credentials. Make sure you're using the latest version of your browser for the best experience.",
                      icon: "🔐",
                    },
                    {
                      title: "Navigate to Profile Section",
                      description:
                        "After logging into your dashboard, navigate to the Profile section in the left-side menu. Here, you can view and manage all your personal information.",
                      icon: "👤",
                    },
                    {
                      title: "Verify Information",
                      description:
                        "Ensure all your personal and academic details are correctly filled before proceeding. Any missing or incorrect information may cause issues with your certificate.",
                      icon: "✅",
                    },
                  ].map((step, index) => (
                    <Paper
                      key={index}
                      elevation={2}
                      className="p-4 transition-all hover:shadow-lg hover:scale-[1.01] transform duration-200"
                    >
                      <div className="flex items-start gap-4">
                        <div className="flex-shrink-0 w-12 h-12 bg-red-100 rounded-full flex items-center justify-center text-2xl">
                          {step.icon}
                        </div>
                        <div className="flex-1">
                          <Typography
                            variant="h6"
                            className="text-gray-800 font-semibold mb-1"
                          >
                            Step {index + 1}: {step.title}
                          </Typography>
                          <Typography variant="body1" className="text-gray-600">
                            {step.description}
                          </Typography>
                        </div>
                      </div>
                    </Paper>
                  ))}

                  {/* Two new images before Step 4 */}
                  <div className="grid grid-cols-2 gap-4 my-6">
                    <div className="flex justify-center">
                      <img
                        src={aictesample1}
                        alt="AICTE Process1"
                        className="rounded-lg shadow-lg w-full object-cover"
                      />
                    </div>
                    <div className="flex justify-center">
                      <img
                        src={aictesample2}
                        alt="AICTE Process 2"
                        className="rounded-lg shadow-lg w-full object-cover"
                      />
                    </div>
                  </div>

                  {/* Step 4 */}
                  <Paper
                    elevation={2}
                    className="p-4 transition-all hover:shadow-lg hover:scale-[1.01] transform duration-200"
                  >
                    <div className="flex items-start gap-4">
                      <div className="flex-shrink-0 w-12 h-12 bg-red-100 rounded-full flex items-center justify-center text-2xl">
                        💾
                      </div>
                      <div className="flex-1">
                        <Typography
                          variant="h6"
                          className="text-gray-800 font-semibold mb-1"
                        >
                          Step 4: Generate PDF
                        </Typography>
                        <div className="space-y-2">
                          <Typography
                            variant="body1"
                            className="text-gray-700 leading-relaxed"
                          >
                            Follow these steps carefully:
                          </Typography>
                          <div className="pl-4 border-l-4 border-red-400">
                            <ol className="list-decimal space-y-2 ml-4">
                              <li className="text-gray-700">
                                <strong className="text-red-600 font-semibold">
                                  Right-click
                                </strong>{" "}
                                on the page
                              </li>
                              <li className="text-gray-700">
                                Select{" "}
                                <strong className="text-red-600 font-semibold">
                                  'Print'
                                </strong>{" "}
                                from the menu
                              </li>
                              <li className="text-gray-700">
                                In the printer options,{" "}
                                <strong className="text-red-600 font-semibold">
                                  **strictly**
                                </strong>{" "}
                                choose{" "}
                                <strong className="text-red-600 font-semibold">
                                  'Save as PDF'
                                </strong>{" "}
                                as your destination - no other option will be
                                accepted
                              </li>
                              <li className="text-gray-700">
                                Click the{" "}
                                <strong className="text-red-600 font-semibold">
                                  'Save'
                                </strong>{" "}
                                button
                              </li>
                              <li className="text-gray-700">
                                Choose your preferred{" "}
                                <strong className="text-red-600 font-semibold">
                                  save location
                                </strong>{" "}
                                and confirm
                              </li>
                            </ol>
                          </div>
                          <Typography
                            variant="body2"
                            className="text-gray-500 italic mt-2"
                          >
                            Note: Make sure all information is correct before
                            generating the PDF
                          </Typography>
                          <div className="mt-3 p-2 bg-yellow-50 border-l-4 border-yellow-400 text-yellow-800">
                            <strong>Important:</strong> Only PDF files generated
                            using the 'Save as PDF' option will be accepted.
                            Screenshots or scanned copies will not be valid.
                          </div>
                        </div>
                      </div>
                    </div>
                  </Paper>
                </div>
              </div>

              {/* Fixed Footer */}
              <div className="p-4 border-t border-gray-200 bg-white bottom-0 rounded-b-lg">
                <div className="flex justify-end">
                  <Button
                    variant="contained"
                    onClick={() => setOpenAicteModal(false)}
                    sx={{
                      backgroundColor: "#dc2626",
                      "&:hover": {
                        backgroundColor: "#b91c1c",
                      },
                      textTransform: "none",
                      px: 3,
                      py: 1,
                      fontSize: "0.8rem",
                      fontWeight: 500,
                    }}
                  >
                    Got it, thanks!
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>

          {/* Submit Button */}
          <div className="text-right">
            <button
              type="submit"
              className={`${
                isAicteVerified ? "bg-red-600" : "bg-gray-400"
              } text-white py-2 px-4 rounded-lg flex items-center justify-center`}
              disabled={isSubmitting || !isAicteVerified}
            >
              {isSubmitting ? (
                <>
                  <LoadingIcon />
                  Updating Profile...
                </>
              ) : (
                "Update Profile"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewPersonalDetailsForm;
