// import React from "react";
// import { Navigate } from "react-router-dom";

// // publicRoutes...................
// import Login from "../pages/Auth/Login";
// import PageNotFound from "../pages/404/PageNotFound";

// // authProtectedSpoc..............
// import Dashboard from "../pages/Dashboard";
// import Internship from "../pages/Internship";
// import Company from "../pages/Company";
// import Placement from "../pages/Placement";
// import Resume from "../pages/Resume";
// import Report from "../pages/Report";
// import Support from "../pages/Support";
// import AcademyProgram from "../pages/AcademyProgram";
// import InstitutionStatus from "../pages/InstitutionStatus";
// import CorporateProgram from "../pages/CorporateProgram";
// import Educator from "../pages/Educator";
// import NewsEvents from "../pages/News&Events";
// import Publication from "../pages/Publication";
// import AwardsRecognition from "../pages/Awards&Recognition";

// import Settings from "../pages/Auth/Settings";
// import InternshipApproval from "../pages/Internship/InternApproval";
// // import Membership from "../pages/Membership";
// import TalentConnect from "../pages/TalentConnect";
// import Agreements from "../pages/Membership/agreements";
// // import Payment from "../pages/Membership/payment";
// import InstitutionalProfile from "../pages/Membership/InstitutionalProfile/InstitutionalProfile";
// import FeedbackForm from "../pages/Feedback";
// // Admin
// import AdminEducator from "../pages/Admin/Educator";
// import Academy from "../pages/Admin/Academy";
// import Institute from "../pages/Admin/Institute";

// const authProtected = [
//   {
//     path: "/dashboard",
//     component: <Dashboard />,
//     roles: [
//       "spoc",
//       "manager",
//       "management",
//       "educator",
//       "account_manager",
//       "talent_module",
//       "admin",
//       "staff",
//     ],
//   },
//   {
//     path: "/",
//     exact: true,
//     component: <Navigate to="/dashboard" />,
//   },
//   { path: "*", component: <Navigate to="/dashboard" /> },
// ];

// //

// const authProtectedRoutes = [
//   {
//     path: "/dashboard",
//     component: <Dashboard />,
//     roles: [
//       "spoc",
//       "manager",
//       "management",
//       "educator",
//       "account_manager",
//       "talent_module",
//       "admin",
//       "staff",
//     ],
//   },

//   {
//     path: "/feedback",
//     component: <FeedbackForm />,
//     roles: [
//       "spoc",
//       "manager",
//       "management",
//       "educator",
//       "account_manager",
//       "admin",
//     ],
//   },
//   {
//     path: "/settings",
//     component: <Settings />,
//     roles: [
//       "spoc",
//       "manager",
//       "management",
//       "educator",
//       "account_manager",
//       "admin",
//       "staff",
//     ],
//   },

//   {
//     path: "/internship",
//     component: <Internship />,
//     roles: [
//       "spoc",
//       "manager",
//       "management",
//       "educator",
//       "account_manager",
//       "admin",
//     ],
//   },

//   {
//     path: "/company",
//     component: <Company />,
//     roles: ["talent_module"],
//   },

//   {
//     path: "/placement",
//     component: <Placement />,
//     roles: ["talent_module"],
//   },
//   {
//     path: "/internship-approval",
//     component: <InternshipApproval />,
//     roles: ["spoc"],
//   },
//   {
//     path: "/resume",
//     component: <Resume />,
//     roles: ["talent_module"],
//   },

//   {
//     path: "/report",
//     component: <Report />,
//     roles: ["spoc", "manager", "talent_module", "admin"],
//   },

//   {
//     path: "/support",
//     component: <Support />,
//     roles: ["spoc", "manager", "talent_module", "educator", "admin"],
//   },

//   // Admin..........................................

//   {
//     path: "/academy",
//     component: <Academy />,
//     roles: ["admin"],
//   },
//   {
//     path: "/institute",
//     component: <Institute />,
//     roles: ["admin","staff"],
//   },
//   {
//     path: "/admin-educator",
//     component: <AdminEducator />,
//     roles: ["admin"],
//   },

//   //.................................................

//   {
//     path: "/academy-program",
//     component: <AcademyProgram />,
//     roles: ["account_manager", "manager", "educator", "admin"],
//   },

//   {
//     path: "/institution-status",
//     component: <InstitutionStatus />,
//     roles: ["spoc", "manager", "account_manager", "admin"],
//   },

//   {
//     path: "/corporate-program",
//     component: <CorporateProgram />,
//     roles: ["spoc", "management"],
//   },

//   {
//     path: "/educator",
//     component: <Educator />,
//     roles: ["spoc", "educator", "management","staff"],
//   },

//   {
//     path: "/news-events",
//     component: <NewsEvents />,
//     roles: ["spoc"],
//   },

//   {
//     path: "/publication",
//     component: <Publication />,
//     roles: ["spoc"],
//   },

//   {
//     path: "/awards-recognition",
//     component: <AwardsRecognition />,
//     roles: ["spoc"],
//   },

//   // {
//   //   path: "/membership",
//   //   component: <Membership />,
//   //   roles: ["manager", "management", "spoc"],
//   // },
//   {
//     path: "/membership/agreements",
//     component: <Agreements />,
//     roles: ["manager", "management", "spoc", "admin"],
//   },
//   // {
//   //   path: "/membership/payment",
//   //   component: <Payment />,
//   //   roles: ["manager", "management", "spoc","admin"],
//   // },
//   {
//     path: "/membership/institutional-details",
//     component: <InstitutionalProfile />,
//     roles: ["manager", "management", "spoc", "admin"],
//   },

//   {
//     path: "/talent-connect",
//     component: <TalentConnect />,
//     roles: ["spoc"],
//   },

//   {
//     path: "/",
//     exact: true,
//     component: <Navigate to="/dashboard" />,
//   },
// ];

// const publicRoutes = [
//   { path: "/login", component: <Login /> },
//   { path: "/page-not-found", component: <PageNotFound /> },
//   { path: "*", component: <Navigate to="/page-not-found" /> },
// ];

// export { authProtectedRoutes, publicRoutes, authProtected };

import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

import Student from "../pages/Student/Student";
import Assement from "../pages/Assement/Assement";

// publicRoutes...................
import Login from "../pages/Auth/Login";
import Dashboard from "../pages/Dashboard";
import PageNotFound from "../pages/404/PageNotFound";
import Home from "../pages/Home/Home";
import Assessments from "../pages/Assement/Assessments";
import Instructions from "../pages/Assement/Instructions";
import UploadCertificate from "../pages/Internship/UploadCertificate";
import MyInternship from "../pages/Internship/MyInternship";
import Courses from "../pages/Internship/Courses";
import Coursess from "../pages/Internship/Coursess";
import OpeningJobs from "../pages/Jobs/OpeningJobs";
import MyJobs from "../pages/Jobs/MyJobs";
import Jobdescription from "../pages/Jobs/Jobdescription";
import AppliedJob from "../pages/Jobs/AppliedJob";
import Jobdetails from "../pages/Jobs/Jobdetails";
import Profile from "../pages/Profile/Profile";
import Register from "../pages/Register/Register";
import Rigister from "../pages/Register/Rigister";
import AllPerformance from "../pages/performance/AllPerformance";
import Certificate from "../pages/Internship/Certificate";
import ApscheCertificate from "../pages/Internship/ApscheCertificate";
import ApscheLongTermCertificate from "../pages/Internship/ApscheLongTermCertificate";
import NewReg from "../pages/Internship/NewReg";
import DomainChoose from "../components/Domains Choose/DomainChoose";
import CertificateUpload from "../pages/Internship/CertificateUpload";
import Faq from "../pages/Internship/Faq";
import PersonalDetailsForm from "../pages/PersonalProfile/PersonalForm";
import NewPersonalDetailsForm from "../pages/PersonalProfile/NewPersonalForm";
import NewCertificateUpload from "../pages/Internship/NewCertificateUpload";
import ErrorBoundary from "../pages/PersonalProfile/ErrorBoundary";
import TailLogin from "../pages/Auth/TailLogin";
import {
  getOngoingInternships,
  profileGetDataService,
} from "../services/dataService";
import { Box, CircularProgress } from "@mui/material";
import NewlyPersonalDetailsForm from "../pages/PersonalProfile/NewlyPersonalForm";

const ProfileStatusGuard = ({ children }) => {
  const [isAllowed, setIsAllowed] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const checkProfileStatus = async () => {
      try {
        const profileResponse = await profileGetDataService.profileData();
        const isProfileComplete =
          profileResponse.data.profile_update_status === 1;
        setIsAllowed(isProfileComplete || location.pathname === "/profile");
      } catch (error) {
        console.error("Profile check failed:", error);
        setIsAllowed(false);
      }
    };
    checkProfileStatus();
  }, [location.pathname]);

  if (isAllowed === null) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return isAllowed ? children : <Navigate to="/profile" replace />;
};

const DomainGuard = ({ children, type }) => {
  const [isAllowed, setIsAllowed] = useState(null);

  useEffect(() => {
    const checkDomainAccess = async () => {
      try {
        const response = await getOngoingInternships.ongoingInternshipsData();
        const restrictedDomains =
          type === "assessment"
            ? [13, 25, 9, 6, 20, 26, 27, 28, 36, 37]
            : [26, 27, 28, 36, 37];

        const hasRestrictedDomain = response.data.internships?.some(
          (internship) => restrictedDomains.includes(internship.domain_id)
        );
        setIsAllowed(!hasRestrictedDomain);
      } catch (error) {
        console.error("Domain check failed:", error);
        setIsAllowed(false);
      }
    };
    checkDomainAccess();
  }, [type]);

  if (isAllowed === null) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return isAllowed ? children : <Navigate to="/internship" replace />;
};

const authProtected = [
  // {
  //   path: "/dashboard",
  //   component: <Dashboard />,
  //   roles: ["Student"],
  // },
  {
    path: "/assessment",
    component: <Assessments />,
    roles: ["Student"],
  },
  // {
  //   path: "/",
  //   exact: true,
  //   component: <Navigate to="/dashboard" />,
  // },
  // {
  //   path: "/profile",
  //   exact: true,
  //   component: <Navigate to="/profile" />,
  // },
  // { path: "*", component: <Navigate to="/dashboard" /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/assessment" />,
  },
  { path: "*", component: <Navigate to="/assessment" /> },
];

const authProtectedRoutes = [
  // {
  //   path: "/dashboard",
  //   component: <Dashboard />,
  //   roles: ["Student"],
  // },
  // {
  //   path: "/student",
  //   component: <Student />,
  //   roles: ["Student"],
  // },
  {
    path: "/assessment",
    component: (
      <ProfileStatusGuard>
        <DomainGuard type="assessment">
          <Assessments />
        </DomainGuard>
      </ProfileStatusGuard>
    ),
    roles: ["Student"],
  },
  // {
  //   path: "/performance",
  //   component: <AllPerformance />,
  //   roles: ["Student"],
  // },
  {
    path: "/instructions",
    component: (
      <ProfileStatusGuard>
        <Instructions />
      </ProfileStatusGuard>
    ),
    roles: ["Student"],
  },
  {
    path: "/cohort/assessment",
    component: (
      <ProfileStatusGuard>
        <Assement />
      </ProfileStatusGuard>
    ),
    roles: ["Student"],
  },
  {
    path: "/internship/certificate/short-term",
    component: (
      <ProfileStatusGuard>
        <ApscheCertificate />
      </ProfileStatusGuard>
    ),
    roles: ["Student"],
  },
  {
    path: "/internship/certificate/long-term",
    component: (
      <ProfileStatusGuard>
        {" "}
        <ApscheLongTermCertificate />
      </ProfileStatusGuard>
    ),
    roles: ["Student"],
  },
  // {
  //   path: "/internship/certificate/varification",
  //   component: <UploadCertificate />,
  //   roles: ["Student"],
  // },
  {
    path: "/internship/certificate",
    component: (
      <ProfileStatusGuard>
        <Certificate />
      </ProfileStatusGuard>
    ),
    roles: ["Student"],
  },
  {
    path: "/internship",
    component: (
      <ProfileStatusGuard>
        <MyInternship />
      </ProfileStatusGuard>
    ),
    roles: ["Student"],
  },
  // {
  //   path: "/internship/registration",
  //   component: <Courses />,
  //   roles: ["Student"],
  // },
  {
    path: "/jobs/opening",
    component: (
      <ProfileStatusGuard>
        {" "}
        <OpeningJobs />
      </ProfileStatusGuard>
    ),
    roles: ["Student"],
  },
  {
    path: "/jobs/details",
    component: (
      <ProfileStatusGuard>
        {" "}
        <Jobdetails />
      </ProfileStatusGuard>
    ),
    roles: ["Student"],
  },
  {
    path: "/jobs/applied",
    component: (
      <ProfileStatusGuard>
        {" "}
        <MyJobs />
      </ProfileStatusGuard>
    ),
    roles: ["Student"],
  },
  {
    path: "/jobs/applied/view",
    component: (
      <ProfileStatusGuard>
        {" "}
        <AppliedJob />
      </ProfileStatusGuard>
    ),
    roles: ["Student"],
  },
  {
    path: "/jobs/descriptions",
    component: (
      <ProfileStatusGuard>
        {" "}
        <Jobdescription />
      </ProfileStatusGuard>
    ),
    roles: ["Student"],
  },
  // {
  //   path: "/internship/faq",
  //   component: <Faq />,
  //   roles: ["Student"],
  // },
  // {
  //   path: "/home",
  //   component: <Home />,
  //   roles: ["Student"],
  // },
  // {
  //   path: "/",
  //   exact: true,
  //   component: <Navigate to="/dashboard" />,
  // },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/internship" />,
  },
  {
    path: "/profile",
    component: (
      <ProfileStatusGuard>
        <ErrorBoundary>
          <PersonalDetailsForm />
        </ErrorBoundary>
      </ProfileStatusGuard>
    ),
    roles: ["Student"],
  },
  {
    path: "/update-profile",
    component: (
      <ProfileStatusGuard>
        <ErrorBoundary>
          <NewPersonalDetailsForm />
        </ErrorBoundary>
      </ProfileStatusGuard>
    ),
    roles: ["Student"],
  },
  // {
  //   path: "/profiles",
  //   component: <Profile />,
  //   roles: ["Student"],
  // },
  // {
  //   path: "/domain-choose",
  //   component: <DomainChoose />,
  //   roles: ["Student"],
  // },
  {
    path: "/cert-upload",
    component: (
      <ProfileStatusGuard>
        <DomainGuard type="cert-upload">
          <CertificateUpload />
        </DomainGuard>
      </ProfileStatusGuard>
    ),
    roles: ["Student"],
  },
  // {
  //   path: "/newreg",
  //   component: <NewReg />,
  //   roles: ["Student"],
  // },
];

const publicRoutes = [
  // { path: "/login", component: <TailLogin /> },
  // { path: "/register", component: <Register /> },
  // { path: "/rigister", component: <Rigister /> },
  { path: "/page-not-found", component: <PageNotFound /> },
  { path: "*", component: <Navigate to="/page-not-found" /> },
];

export { authProtectedRoutes, publicRoutes, authProtected };
