import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Typography,
  Box,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Modal,
} from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../services/configUrls";
import genai from "../../assets/Google GenAI.pdf";
import { CrossIcon, X } from "lucide-react";

//const API_URL = "http://192.168.1.6:8000";

// Demo data for the modal content
// Demo data for the modal content
// Demo data for the modal content with image URLs from Unsplash
// const domainDetails = {
//   17: {
//     domain_name: "Artificial Intelligence",
//     content: [
//       "Introduction to AI",
//       "Basics of Machine Learning",
//       "Hands-on with Data Science",
//     ],
//     willDo: [
//       "Participate in AI workshops",
//       "Work on projects with industry experts",
//       "Collaborate with peers",
//     ],
//     willGet: [
//       "Certificate of completion",
//       "Opportunity for internships",
//       "Access to premium AI resources",
//     ],
//     images: [
//       "https://images.unsplash.com/photo-1673422672504-757a7a57d24a?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//       "https://images.unsplash.com/photo-1720547014555-539d9d6cd2f5?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//       "https://images.unsplash.com/photo-1720547009638-a510e0fb9c09?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//     ],
//   },
//   19: {
//     domain_name: "Cloud Computing",
//     content: [
//       "Cloud Computing basics",
//       "Virtualization concepts",
//       "Cloud service providers overview",
//     ],
//     willDo: [
//       "Deploy applications on cloud platforms",
//       "Work with cloud infrastructure",
//       "Build and manage cloud services",
//     ],
//     willGet: [
//       "Cloud certifications",
//       "Hands-on cloud deployment experience",
//       "Networking with industry leaders",
//     ],
//     images: [
//       "https://source.unsplash.com/featured/?cloud-certification",
//       "https://source.unsplash.com/featured/?cloud-computing",
//       "https://source.unsplash.com/featured/?networking",
//     ],
//   },
//   // Add more domains as needed
// };

const CopyDomainChoose = () => {
  const [domain, setDomain] = useState("");
  const [preferredDomains, setPreferredDomains] = useState([]);
  const [profile, setProfile] = useState([]);
  const [job, setJob] = useState([]);
  const [edu, setEdu] = useState([]);
  const [optionalDomains, setOptionalDomains] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingDomains, setLoadingDomains] = useState(true);
  const [url, setUrl] = useState("");
  const [showUrlInput, setShowUrlInput] = useState(false);
  const [status, setStatus] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    content: [],
    willDo: [],
    willGet: [],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [eduDetails, setEduDetails] = useState([]);

  const token = localStorage.getItem("accessToken");

  if (!token) {
    window.location.href = "https://eduskillsfoundation.org/login";
  }

  // Fetch both preferred and optional domains
  useEffect(() => {
    const fetchDomains = async () => {
      try {
        // Fetch Preferred Domains
        const preferredResponse = await axios.get(
          `${BASE_URL}/domain/domainChoose`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPreferredDomains(preferredResponse.data[1]);
        setProfile(preferredResponse.data[1]);
        setEdu(preferredResponse.data[1]);
        setJob(preferredResponse.data[2]);
        setStatus(preferredResponse.data[0]);
        setEduDetails(preferredResponse?.data[2]);

        // Fetch Optional Domains
        const optionalResponse = await axios.get(
          `${BASE_URL}/domain/optionalDomains`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setOptionalDomains(optionalResponse.data);
        console.log(optionalDomains);
      } catch (error) {
        console.error("Error fetching domains:", error);
        toast.error("An Error Occurred", {
          position: toast.POSITION.TOP_CENTER,
        });
      } finally {
        setLoadingDomains(false);
      }
    };

    fetchDomains();
  }, [token]);

  const googleGenAiUrlRegex =
    /^https:\/\/www\.cloudskillsboost\.google\/public_profiles\//;

  // Handle form submission
  const handleSubmit = async () => {
    if (!domain) {
      toast.error("Please select a domain", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (domain === "32" && !googleGenAiUrlRegex.test(url)) {
      toast.error("Invalid Google Gen AI URL", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/domain/domainSubmit`,
        {
          domain_id: domain,
          url: domain === "32" ? url : null,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(response.data[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
      window.location.reload();
      setDomain("");
      setUrl("");
      setShowUrlInput(false);
    } catch (error) {
      console.error("Error submitting domain:", error);
      toast.error("Failed to submit domain", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Function to find domain name based on domain_id
  const findDomainName = (domainId) => {
    const preferredDomain = preferredDomains.find(
      (item) => item.domain_id === domainId
    );
    if (preferredDomain) return preferredDomain.domain_name;

    const optionalDomain = optionalDomains.find(
      (item) => item.domain_id === domainId
    );
    if (optionalDomain) return optionalDomain.domain_name;

    return "Domain Details";
  };

  // Open modal with content based on selected domain
  const handleKnowMoreOpenModal = async (domainId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/domain/courseOutline/${domainId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Check if response data is an array and has at least one element
      if (Array.isArray(response.data) && response.data.length > 0) {
        const { lab_content, assessment, outcome_of_course } = response.data[0];

        setModalContent({
          content: lab_content || [],
          willDo: assessment || [],
          willGet: outcome_of_course || [],
          domain_name: findDomainName(domainId),
        });
        setOpenModal(true);
      } else {
        toast.error("No content available for this domain", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      console.error("Error fetching course outline:", error);
      toast.error("Failed to fetch domain details", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  // Handle domain selection change
  const handleDomainChange = (event) => {
    const selectedDomain = event.target.value;
    setDomain(selectedDomain);
    setShowUrlInput(selectedDomain === "32");
    if (selectedDomain !== "32") {
      setUrl("");
    }
  };

  // Function to open the modal
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const shouldShowCohortMessage =
    !loadingDomains &&
    preferredDomains.length === 0 &&
    optionalDomains.length === 0 &&
    status === "0";

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      marginTop="100px"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding={4}
        boxShadow={3}
        borderRadius={2}
        width={{ xs: "100%", sm: "1200px" }} // Adjust width as per your needs
      >
        {/* <Typography variant="h5" gutterBottom>
          Domain Selection
        </Typography> */}

        {status === "1" && (
          <div className="flex items-center justify-center px-8 py-8 bg-gray-100">
            <div className="max-w-4xl w-full bg-white shadow-lg rounded-lg p-6 text-center">
              <h1 className="text-2xl font-bold text-gray-800 mb-4">
                Successfully applied for the AICTE - EduSkills Virtual
                Internship.
              </h1>
              <p className="text-gray-600 text-xl text-left mb-4">
                Application has been sent to your institute for approval. Please
                contact to your SPOC
                <br></br>
                <br></br>
                Name : <b>{eduDetails[0]}</b>
                <br></br>
                email : <b>{eduDetails[1]}</b>
                <br></br>
                mobile : <b>{eduDetails[2]}</b>
                <br></br>
              </p>
            </div>
          </div>
        )}

        {status === "7" && (
          <div className="flex items-center justify-center px-8 py-8 bg-gray-100">
            <div className="max-w-4xl w-full bg-white shadow-lg rounded-lg p-6 text-center">
              <h1 className="text-2xl font-bold text-gray-800 mb-4">
                Successfully applied for the AICTE - EduSkills Virtual
                Internship.
              </h1>
              <p className="text-gray-600 text-xl text-left mb-4">
                Application has been sent for approval
              </p>
            </div>
          </div>
        )}
        {status === "2" && (
          <Typography color="red" variant="body1" gutterBottom>
            Your application has been rejected. Please contact your SPOC.
          </Typography>
        )}

        {status === "3" && (
          <div className="flex items-center justify-center px-8 py-8 bg-gray-100">
            <div className="max-w-4xl w-full bg-white shadow-lg rounded-lg p-6 text-center">
              <h1 className="text-2xl font-bold text-gray-800 mb-4">
                The internship is not opened to your institution
              </h1>
              <p className="text-gray-600 text-xl mb-4">
                Please contact to your Faculty.
              </p>
            </div>
          </div>
        )}

        {status === "5" && (
          <div className="flex items-center justify-center px-8 py-8 bg-gray-100">
            <div className="max-w-4xl w-full bg-white shadow-lg rounded-lg p-6 text-center">
              <h1 className="text-lg text-left text-gray-800 mb-4">
                <span className="font-bold">
                  Congratulations... on completing your {profile} {job} !
                </span>
                <br></br>
                <br></br> You are now an esteemed alumni of EduSkills. We are
                proud of your accomplishments and wish you continued success in
                your career.<br></br>
                <br></br> If you are interested in furthering your education and
                expanding your skillset, we encourage you to explore the various
                courses and opportunities that EduSkills has to offer.
              </h1>
              {/* <a
                href="https://eduskills.academy"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-blue-600 text-white text-sm py-3 px-6 rounded-lg hover:bg-blue-700 transition duration-300"
              >
                Explore Now
              </a> */}
            </div>
          </div>
        )}
        {status === "6" && (
          <div className="flex items-center justify-center px-8 py-8 bg-gray-100">
            <div className="max-w-4xl w-full bg-white shadow-lg rounded-lg p-6 text-center">
              <h1 className="text-lg text-left text-gray-800 mb-4">
                We regret to inform you that we have not received your Email ID
                from your College/University for the Cohort-10 Internship
                program.
                <br></br>
                <br></br>We kindly request that you follow up with your College
                / University SPOC to ensure that the necessary information is
                provided to us as soon as possible :<br></br>
                <br></br>
                {edu[0]}
                <br></br>
                {edu[1]}
                <br></br>
                {edu[2]}
                <br></br>
                <br></br> OR else<br></br> <br></br> You can obtain an EduSkills
                Membership for further Process.
              </h1>
              {/* <a
                href="https://eduskills.academy"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-blue-600 text-white text-sm py-3 px-6 rounded-lg hover:bg-blue-700 transition duration-300"
              >
                Explore Now
              </a> */}
            </div>
          </div>
        )}
        {status === "0" && (
          <>
            {shouldShowCohortMessage ? (
              <div className="flex items-center justify-center px-8 py-12 bg-gray-50 rounded-lg">
                <div className="max-w-2xl w-full bg-white shadow-lg rounded-lg p-8 text-center">
                  <h1 className="text-2xl font-bold text-gray-800 mb-4">
                    Cohort 10 Closed
                  </h1>
                  <div className="text-gray-600 text-lg space-y-4">
                    <p>
                      The registration for Cohort 11 will open on December 15th,
                      2024.
                    </p>
                    {/* <p className="text-blue-600">
                      Stay tuned for exciting learning opportunities!
                    </p> */}
                  </div>
                  {/* <div className="mt-8 p-4 bg-blue-50 rounded-lg">
                    <p className="text-sm text-gray-600">
                      We're preparing an enhanced learning experience for our
                      next cohort. Mark your calendar and don't miss out on this
                      opportunity!
                    </p>
                  </div> */}
                </div>
              </div>
            ) : (
              <FormControl component="fieldset" fullWidth margin="normal">
                <Grid container spacing={2}>
                  {/* Left Column - Preferred Domains */}
                  <Grid item xs={12} sm={12}>
                    <Typography
                      textAlign={"center"}
                      bgcolor={"#e2e2e2"}
                      marginBottom={2}
                      variant="h6"
                    >
                      Preferred Domains
                    </Typography>
                    <RadioGroup
                      aria-label="preferred-domain"
                      name="preferred-domain"
                      value={domain}
                      onChange={handleDomainChange}
                    >
                      {loadingDomains ? (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          marginTop={2}
                        >
                          <CircularProgress />
                        </Box>
                      ) : (
                        preferredDomains.map((domainItem) => (
                          <div
                            key={domainItem.domain_id}
                            className="flex items-center justify-left"
                          >
                            <FormControlLabel
                              sx={{ marginBottom: "15px" }}
                              value={domainItem.domain_id.toString()}
                              control={
                                <Radio
                                  sx={{ padding: 0.5, fontSize: "0.8rem" }}
                                />
                              }
                              label={domainItem.domain_name}
                            />
                            <Button
                              variant="outlined"
                              size="small"
                              color="info"
                              className="text-blue-500 hover:text-blue-700"
                              sx={{ marginBottom: "15px" }}
                              onClick={() =>
                                handleKnowMoreOpenModal(domainItem.domain_id)
                              }
                            >
                              Know More
                            </Button>
                          </div>
                        ))
                      )}
                    </RadioGroup>
                  </Grid>

                  {/* Right Column - Optional Domains */}
                  <Grid item xs={12} sm={12}>
                    <Typography
                      textAlign={"center"}
                      bgcolor={"#e2e2e2"}
                      marginBottom={2}
                      variant="h6"
                    >
                      Optional Domains
                    </Typography>
                    <RadioGroup
                      aria-label="optional-domain"
                      name="optional-domain"
                      value={domain}
                      onChange={handleDomainChange}
                    >
                      {loadingDomains ? (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          marginTop={2}
                        >
                          <CircularProgress />
                        </Box>
                      ) : (
                        optionalDomains.map((domainItem) => (
                          <div
                            key={domainItem.domain_id}
                            className="flex items-center justify-left"
                          >
                            <FormControlLabel
                              value={domainItem.domain_id.toString()}
                              sx={{ marginBottom: "15px" }}
                              control={
                                <Radio
                                  sx={{ padding: 0.5, fontSize: "0.8rem" }}
                                />
                              }
                              label={domainItem.domain_name}
                            />
                            <Button
                              variant="outlined"
                              size="small"
                              color="info"
                              className="text-blue-500 hover:text-blue-700"
                              sx={{ marginBottom: "15px" }}
                              onClick={() =>
                                handleKnowMoreOpenModal(domainItem.domain_id)
                              }
                            >
                              Know More
                            </Button>
                          </div>
                        ))
                      )}
                    </RadioGroup>
                  </Grid>
                </Grid>
              </FormControl>
            )}

            {showUrlInput && (
              <>
                <TextField
                  fullWidth
                  label="Google Gen AI URL"
                  variant="outlined"
                  margin="normal"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  placeholder="Enter Google Gen AI URL"
                />
                <div className="mt-2">
                  <button
                    onClick={handleOpenModal}
                    className="inline-flex items-center bg-red-600 text-white text-sm py-2 px-4 rounded-lg hover:bg-red-700 transition duration-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 mr-2"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414a2 2 0 00-.586-1.414l-4-4A2 2 0 009.586 2H6zM5 4a1 1 0 011-1h3v4a1 1 0 001 1h4v10a1 1 0 01-1 1H6a1 1 0 01-1-1V4zm7 0l3 3h-3V4z"
                        clipRule="evenodd"
                      />
                    </svg>
                    How to get the URL
                  </button>
                </div>
              </>
            )}

            {!shouldShowCohortMessage && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={isLoading || loadingDomains}
                fullWidth
                size="large"
                sx={{ marginTop: 2 }}
              >
                {isLoading ? <CircularProgress size={24} /> : "Submit"}
              </Button>
            )}
          </>
        )}

        {/* Modal for Domain Details */}
        {/* Modal for Domain Details */}
        {/* Modal for Domain Details */}
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              bgcolor: "background.paper",
              p: 4,
              borderRadius: 2,
              boxShadow: 24,
              maxWidth: { xs: "90%", sm: "75%", md: "60%", lg: "50%" },
              width: "100%",
              mx: "auto",
              position: "relative",
              top: { xs: "40%", sm: "50%" }, // Adjust vertical positioning based on screen size
              transform: "translateY(-50%)", // Center vertically by shifting up
            }}
          >
            {/* Close Button - Fixed at Top Right */}
            <Button
              onClick={() => setOpenModal(false)}
              sx={{
                position: "absolute",

                top: "3px",
                right: "3px",
                minWidth: "20px",
                minHeight: "20px",
                padding: "4px",
                color: "#000",
                bgcolor: "#e2e2e2",
                "&:hover": {
                  bgcolor: "#e1e1e1",
                },
              }}
            >
              <X></X>
            </Button>

            <Typography
              id="modal-title"
              variant="h6"
              textAlign="center"
              sx={{
                fontSize: { xs: "1.2rem", sm: "1.4rem" },
                fontWeight: "bold",
                color: "#1a73e8",
                mb: 2,
              }}
            >
              {modalContent.domain_name}
            </Typography>

            <Box
              sx={{
                maxHeight: "60vh",
                overflowY: "auto",
                pr: 1,
                "&::-webkit-scrollbar": { width: "8px" },
                "&::-webkit-scrollbar-track": { bgcolor: "#f1f1f1" },
                "&::-webkit-scrollbar-thumb": {
                  bgcolor: "#1a73e8",
                  borderRadius: "10px",
                },
              }}
            >
              <Typography sx={{ fontWeight: "bold", fontSize: "1rem" }}>
                Internship Course/Lab Content:
              </Typography>
              <ul className="list-disc ml-6 mb-4 text-sm">
                {modalContent.content.map((item, index) => (
                  <li className="mb-1" key={index}>
                    {item}
                  </li>
                ))}
              </ul>

              <Typography sx={{ fontWeight: "bold", fontSize: "1rem" }}>
                Assessment:
              </Typography>
              <ul className="list-disc ml-6 mb-4 text-sm">
                {modalContent.willDo.map((item, index) => (
                  <li className="mb-1" key={index}>
                    {item}
                  </li>
                ))}
              </ul>

              <Typography sx={{ fontWeight: "bold", fontSize: "1rem" }}>
                What you will get:
              </Typography>
              <ul className="list-disc ml-6 mb-4 text-sm">
                {modalContent.willGet.map((item, index) => (
                  <li className="mb-1" key={index}>
                    {item}
                  </li>
                ))}
              </ul>
            </Box>
          </Box>
        </Modal>

        {isModalOpen && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white w-[100%] md:w-3/4 lg:w-1/2 p-4 rounded-lg shadow-lg relative">
              <h2 className="text-xl font-bold mb-4">How to get the URL</h2>
              <iframe
                src={`${genai}#toolbar=0`}
                title="PDF"
                className="w-full h-96"
              ></iframe>

              <button
                onClick={handleCloseModal}
                className="absolute top-4 right-4 bg-red-600 text-white py-1 px-3 rounded hover:bg-red-700"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </Box>
      {/* <ToastContainer /> */}
    </Box>
  );
};

export default CopyDomainChoose;
