import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Drawer,
  useTheme,
  // Button,
  // Tooltip,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../theme";
import { MenuItems } from "./navConfig";
import LogoDark from "../assets/imgs/logo-dark.svg";
import LogoLight from "../assets/imgs/logo-light.svg";
import Questions from "../components/sidebar/Questions";
import { profileGetDataService } from "../services/dataService";
// import { Icon } from "@iconify/react";
// import { useSelector } from "react-redux";

const Item = ({
  title,
  to,
  icon,
  selected,
  setSelected,
  isMobileView,
  setToggleDrawer,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleSelection = () => {
    setSelected(title);
    if (isMobileView) {
      setToggleDrawer(false);
    }
  };
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={handleSelection}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = ({ toggleDrawer, setToggleDrawer }) => {
  const menuItems = MenuItems();
  const location = useLocation();
  const currentPath = location.pathname;
  console.log("current path", currentPath);
  const navigate = useNavigate();
  // const isActiveRole = useSelector((state) => state.authorise.userRole);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Dashboard");
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth < theme.breakpoints.values.lg
  );
  const [profileUpdateStatus, setProfileUpdateStatus] = useState(1);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await profileGetDataService.profileData(); // Fetch the data from your service
        const data = response?.data;

        

        setProfileUpdateStatus(data?.profile_update_status);
      } catch (error) {
        console.error("Failed to fetch profile data:", error);
      }
    };

    fetchProfileData();
  }, []);
  useEffect(() => {
    const selectedItem = menuItems.find((nav) => nav.path === currentPath);
    console.log("select item", selectedItem);
  }, [currentPath, menuItems]);

  useEffect(() => {
    // Find the current child item matching the path
    const selectedChild = menuItems.find((nav) => nav?.children);
    console.log("selected children", selectedChild?.children);
    const selectedChildItem = selectedChild?.children.find(
      (nav) => nav.path === currentPath
    );
    console.log("selected child item ==>", selectedChildItem);
    if (selectedChildItem) {
      setSelected(selectedChildItem?.title);
    }
  }, [currentPath, menuItems]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < theme.breakpoints.values.lg);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [theme.breakpoints.values.lg]);

  const SidebarContent =
    profileUpdateStatus !== null && profileUpdateStatus === 1 ? (
      <Box
        sx={{
          "& .pro-sidebar-inner": {
            background: `${colors.primary[400]} !important`,
          },
          "& .pro-icon-wrapper": {
            backgroundColor: "transparent !important",
          },
          "& .pro-inner-item": {
            p: "8px 22px !important",
          },
          "& .pro-inner-item:hover": {
            color: `${colors.blueAccent[400]} !important`,
            borderLeft: `2px solid ${colors.blueAccent[400]}`,
            transition: "width 0.3s ease-out, background-color 0.3s ease-out",
          },
          "& .pro-menu-item.active": {
            color: `${colors.blueAccent[400]} !important`,
            borderLeft: `2px solid ${colors.blueAccent[400]}`,
          },

          "& .logo": {
            display: "flex",
            justifyContent: "center",
            position: "sticky",
            top: 0,
            zIndex: 100,
            bgcolor: colors.primary[400],
          },
          "& .logo img": {
            height: "70px",
            padding: "16px",
            transition: "width 0.3s ease-out",
          },
          bgcolor: `${colors.primary[400]} !important`,
          height: "100vh",
        }}
      >
        <Box
          sx={{
            [theme.breakpoints.down("lg")]: {
              display: "block",
            },
            height: "100%",
            background: `${colors.primary[400]} !important`,
          }}
        >
          <ProSidebar style={{ position: "relative" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box className="logo">
                <img
                  src={theme.palette.mode === "dark" ? LogoLight : LogoDark}
                  alt="Logo"
                />
              </Box>
            </Box>
            <Menu iconShape="square" style={{ padding: 0, height: "100%" }}>
              {location.pathname === "/assement" ? (
                <Questions />
              ) : (
                <div>
                  {menuItems?.map((nav, idx) => (
                    <div key={idx}>
                      {nav?.children ? (
                        <SubMenu title={nav.title} icon={nav.icon}>
                          {nav?.children?.map((child, childIdx) => (
                            <Item
                              key={childIdx}
                              title={child.title}
                              to={child.path}
                              icon={child.icon}
                              selected={selected}
                              setSelected={setSelected}
                              setToggleDrawer={setToggleDrawer}
                              isMobileView={isMobileView}
                              isDisabled={false} // Enabled items
                            />
                          ))}
                        </SubMenu>
                      ) : (
                        <Item
                          title={nav.title}
                          to={nav.path}
                          icon={nav.icon}
                          selected={selected}
                          setSelected={setSelected}
                          setToggleDrawer={setToggleDrawer}
                          isMobileView={isMobileView}
                          isDisabled={false} // Enabled items
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}
            </Menu>
          </ProSidebar>
        </Box>
      </Box>
    ) : (
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: `${colors.primary[400]} !important`,
          color: colors.grey[100],
        }}
      >
        <Typography variant="h6">
        </Typography>
      </Box>
    );

  return (
    <Box>
      {isMobileView ? (
        <Drawer
          variant="temporary"
          anchor="left"
          open={toggleDrawer}
          onClose={() => setToggleDrawer(false)}
        >
          {SidebarContent}
        </Drawer>
      ) : (
        SidebarContent
      )}
    </Box>
  );
};

export default Sidebar;
