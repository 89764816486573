// import React, { useState } from "react";
// import fortinet from "../../assets/imgs/fortinetimg.jpeg";

// const FortinetInstructions = ({ course }) => {
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   if (course.domain_id !== 12) return null;

//   return (
//     <>
//       <div className="mb-4 bg-blue-50 border border-blue-200 rounded-lg p-4">
//         <div className="flex items-center gap-2 mb-2">
//           <svg
//             className="w-5 h-5 text-blue-500"
//             fill="none"
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             strokeWidth="2"
//             viewBox="0 0 24 24"
//             stroke="currentColor"
//           >
//             <path d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
//           </svg>
//           <h3 className="font-semibold text-blue-900">
//             Instructions for Fortinet Certificate URL
//           </h3>
//         </div>

//         <p className="text-sm text-blue-800 mb-3">
//           Please follow these steps to copy the correct certificate URL from
//           your Fortinet training portal:
//         </p>

//         <div
//           className="cursor-pointer border border-gray-200 rounded-lg overflow-hidden"
//           onClick={() => setIsModalOpen(true)}
//         >
//           <img
//             src={fortinet}
//             alt="Fortinet certificate upload instructions"
//             className="w-full hover:opacity-90 transition-opacity"
//           />
//           <div className="bg-gray-50 p-2 text-center">
//             <p className="text-sm text-gray-600">Click to enlarge image</p>
//           </div>
//         </div>
//       </div>

//       {isModalOpen && (
//         <div
//           className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center backdrop-blur-sm"
//           onClick={() => setIsModalOpen(false)}
//         >
//           <div
//             className="relative w-[90vw] h-[90vh] flex items-center justify-center"
//             onClick={(e) => e.stopPropagation()}
//           >
//             <button
//               onClick={() => setIsModalOpen(false)}
//               className="absolute top-4 right-4 bg-white rounded-full p-2 hover:bg-gray-100"
//             >
//               <svg
//                 className="w-6 h-6"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M6 18L18 6M6 6l12 12"
//                 />
//               </svg>
//             </button>
//             <img
//               src={fortinet}
//               alt="Fortinet certificate upload instructions"
//               className="max-w-full max-h-full object-contain"
//             />
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default FortinetInstructions;

import React, { useState } from "react";
import fortinet from "../../assets/imgs/fortinetimg.jpeg";

const FortinetInstructions = ({ course }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (course.domain_id !== 12) return null;

  return (
    <>
      <div className="mb-4 bg-blue-50 border border-blue-200 rounded-lg p-4">
        <div className="flex items-center gap-2 mb-2">
          <svg
            className="w-5 h-5 text-blue-500"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
          <h3 className="font-semibold text-blue-900">
            Instructions for Fortinet Certificate URL
          </h3>
        </div>

        <p className="text-sm text-blue-800 mb-3">
          Please follow these steps to copy the correct certificate URL from
          your Fortinet training portal:
        </p>

        <div
          className="cursor-pointer border border-gray-200 rounded-lg overflow-hidden"
          onClick={() => setIsModalOpen(true)}
        >
          <img
            src={fortinet}
            alt="Fortinet certificate upload instructions"
            className="w-full hover:opacity-90 transition-opacity"
          />
          <div className="bg-gray-50 p-2 text-center">
            <p className="text-sm text-gray-600">Click to enlarge image</p>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black/50 z-[9999] flex items-center justify-center backdrop-blur-md"
          onClick={() => setIsModalOpen(false)}
        >
          <div
            className="relative w-screen h-screen flex items-center justify-center p-4"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={() => setIsModalOpen(false)}
              className="absolute top-4 right-4 bg-white rounded-full p-2 hover:bg-gray-100"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <img
              src={fortinet}
              alt="Fortinet certificate upload instructions"
              className="max-w-full max-h-full object-contain"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default FortinetInstructions;
