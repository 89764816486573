// import React from "react";
// import { Navigate, Route, useLocation } from "react-router-dom";
// import { useSelector } from "react-redux";
// const AuthProtected = (props) => {
//   const history = useLocation();
//   // const { accessToken, refreshToken } = useSelector((state) => state.auth);
//   const accessToken = localStorage.getItem("accessToken");
//   const refreshToken = localStorage.getItem("refreshToken");
//   const userRole = localStorage.getItem("Authorise");
//   console.log("Here@@@@")
//   console.log("accessToken",accessToken)
//   console.log("refreshToken",refreshToken)
//   console.log("userRole",userRole)
//   if (accessToken && refreshToken) {
//     console.log("Here@@@@1")
//     return <>{props.children}</>;
//   } else {
//     console.log("Here@@@@2")
//     console.log("history.pathname",history.pathname);
//     if (history) {
//       localStorage.setItem("TRIGGERD_PATH", history.pathname);
//     }
//     return (
//       <Navigate to={{ pathname: "/internship", state: { from: props.location } }} />
//     );
//   }
// };

// const AccessRoute = ({ component: Component, ...rest }) => {
//   return (
//     <Route
//       {...rest}
//       render={(props) => {
//         return (
//           <>
//             {" "}
//             <Component {...props} />{" "}
//           </>
//         );
//       }}
//     />
//   );
// };

// export { AuthProtected, AccessRoute };

import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { isTokenExpired } from "./isTokenExpired";

const AuthProtected = (props) => {
  const location = useLocation();
  const { accessToken, refreshToken } = useSelector((state) => state.auth);
  const userRole = useSelector((state) => state.authorise.userRole);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("accessToken"); // Get the token from local storage

    const checkTokenExpiration = () => {
      if (isTokenExpired(token)) {
        // Log out the user if the token is expired
        localStorage.removeItem("accessToken");
        //navigate('/login');
      }
    };

    checkTokenExpiration(); // Check when component mounts

    const interval = setInterval(() => {
      checkTokenExpiration();
    }, 60000); // Optionally check every 60 seconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [navigate]);

  // console.log("accessToken", accessToken);
  // console.log("refreshToken", refreshToken);
  // console.log("userRole", userRole);

  if (accessToken && refreshToken) {
    console.log("User authenticated");
    return <>{props.children}</>;
  } else {
    console.log("User not authenticated");
    console.log("Current location:", location.pathname);
    localStorage.setItem("TRIGGERED_PATH", location.pathname);
    return (window.location.href = "https://eduskillsfoundation.org/login/");
  }
};

export { AuthProtected };

// import React from "react";
// import { Navigate, Route, useLocation } from "react-router-dom";
// import { useSelector } from "react-redux";
// const AuthProtected = (props) => {
//   const history = useLocation();
//   const { accessToken, refreshToken } = useSelector((state) => state.auth);
//   const userRole = localStorage.getItem("Authorise");
//   console.log(userRole);
//   if (accessToken && refreshToken) {
//     return <>{props.children}</>;
//   } else {
//     console.log(history.pathname);
//     if (history) {
//       localStorage.setItem("TRIGGERD_PATH", history.pathname);
//     }
//     return (
//       <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
//     );
//   }
// };

// const AccessRoute = ({ component: Component, ...rest }) => {
//   return (
//     <Route
//       {...rest}
//       render={(props) => {
//         return (
//           <>
//             {" "}
//             <Component {...props} />{" "}
//           </>
//         );
//       }}
//     />
//   );
// };

// export { AuthProtected, AccessRoute };
